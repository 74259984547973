import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import useSnackbar from "hooks/useSnackbar";
import FreshdeskButton from "components/FreshdeskButton";
import FileInput from "components/FormFileInput";
import LoadingButton from "components/LoadingButton";
import { saveAssignmentSubmission } from "services/assignmentsService";

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function SubmitAssignment() {
  const classes = useStyles();

  const { id } = useParams();

  const history = useHistory();

  const showSnackbar = useSnackbar();

  const totalSubmission = React.useRef(0);

  const [loading, setLoading] = React.useState(false);

  const handleClose = () => history.goBack();

  const submitForm = async (values) => {
    try {
      setLoading(true);
      await saveAssignmentSubmission(values, id);
      totalSubmission.current = totalSubmission.current + 1;
      console.log(totalSubmission.current);

      showSnackbar("Submissions uploaded successfully!", "success");
      handleClose();
    } catch (e) {
      console.error("Error uploading assignment submission", e);
      showSnackbar(
        "Uh oh! Unable to process your submission. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={true}
      maxWidth="md"
      fullWidth={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>
        <Typography variant="h6">Submit Assignment</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik initialValues={{ files: [] }} onSubmit={submitForm}>
        <Form>
          <DialogContent dividers>
            <FileInput
              name="files"
              label="File(s) to upload"
              maxFileCount={7}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <FreshdeskButton />
            <LoadingButton
              color="secondary"
              variant="contained"
              type="submit"
              label="Submit"
              loading={loading}
            />
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
}

export default SubmitAssignment;
