import React from "react";
import PropTypes from "prop-types";
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingBottom: theme.spacing(6)
  }
}));

const FormSectionHeader = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography variant="h5" gutterBottom>{children}</Typography>
      <Divider/>
    </div>
  )};

FormSectionHeader.propTypes = {
  children: PropTypes.node
};

export default FormSectionHeader;
