import React from "react";
import {useParams, useHistory} from "react-router-dom";
import LoadingOverlay from "../LoadingOverlay";
import ContentPreview from "../ContentPreview";
import {getSubmissionsByAssignment} from "services/assignmentsService";

function AssignmentSubmissions() {
  const {id} = useParams();

  const history = useHistory();

  const [loading, setLoading] = React.useState(true);

  const [submissions, setSubmissions] = React.useState([]);

  const handleClose = () => history.goBack();

  React.useEffect(() => {
    getSubmissionsByAssignment(id)
      .then(_submissions => {
        setSubmissions(_submissions);
        setLoading(false);
      })
      .catch(e => {
        console.error('error loading submissions', e);
        setLoading(false);
      })
  }, [id]);

  return (
    loading ? <LoadingOverlay /> : (
      <ContentPreview
        open={true}
        title="Assignment Submissions"
        files={submissions}
        onClose={handleClose}
      />
    )
  );
}

export default AssignmentSubmissions;
