import React from "react";
import PropTypes from "prop-types";
import ReactAudioPlayer from "react-audio-player";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  audioPlayerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: theme.palette.common.white,
    padding: 10
  },
  audioPlayer: {
    width: '80%'
  }
}));

function AudioPlayer({ source }) {
  const classes = useStyles();

  return (
    <div className={classes.audioPlayerContainer}>
      <ReactAudioPlayer
        className={classes.audioPlayer}
        src={source}
        controlsList="nodownload"
        controls
      />
    </div>
  );
}

AudioPlayer.propTypes = {
  classes: PropTypes.object
};

export default AudioPlayer;
