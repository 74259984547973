import React from "react";
import lottie from "lottie-web";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import sessionHelper from "services/sessionHelper";
import { logEvent } from "services/analyticsService";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  modalContainer: {
    borderRadius: 6,
    padding: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 6
    },
  },
  icons: {
    position: "absolute",
    right: 0,
    top: 0,
    margin: 4,
    cursor: "pointer"
  },
  paymentSuccessHeader: {
    padding: 8,
    fontWeight: 900,
    fontStyle: "normal",
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  actionSection: {
    width: "100%",
    padding: 12,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: 4
    }
  },
  container: {
    display: "flex"
  },
  rightCotainer: {
    height: 286,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 8,
    [theme.breakpoints.down("sm")]: {
      margin: 6,
      height: 246,
      width: '100%',
      padding: 6,
      margin: 0
    }
  },
  formatContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-evenly",
    }
  },
  formatBox: {
    width: 255,
    height: 48,
    backgroundColor: "#F6F6F6",
    border: "1px solid #BDB6B6",
    borderRadius: 8,
    position: "relative",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "left",
    flexDirection: "column",
    paddingLeft: 48,
    margin: 12,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: 8,
      paddingLeft: 30
    }
  },
  formatBoxText: {
    color: "#000000",
    fontSize: 14,
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12
    }
  },
  selectedFormatBox: {
    backgroundColor: "#FFF8E6",
    border: "1px solid #FFBD12"
  },
  radio: {
    position: "absolute",
    left: 6,
    top: 4,
    [theme.breakpoints.down("sm")]: {
      left: -6,
      top: 4,
    },
  },
  radioV2: {
    color: "#FFBD12 !important",
  },
  buttonV1: {
    width: 175,
    height: 38,
    borderColor: "#0063FA",
    borderRadius: 6,
    fontSize: 16,
    fontWeight: 700,
    color: "#fff",
    backgroundColor: "#0063FA",
    margin: "12px 0 12px 0",
    "&:hover": {
      backgroundColor: "#0063FA",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: 18,
    }
  },
  imgSection: {
    textAlign: "center",
    width: 600,
    height: 296,
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      width: 280,
      height: 145
    }
  },
  link: {
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10
    }
  },
}));

const BatchExpiryStatus = ({
  open,
  handleClose,
}) => {
  const classes = useStyles();

  const containerRef = React.useRef(null);

  const [showAnimation, setShowAnimation] = React.useState(false);

  const onCloseClick = () => {
   logEvent("PRESCHOOL_REMINDER_CLOSED", {
      courseTypeName: "Preschool",
      userId: sessionHelper.isLoggedIn() ? sessionHelper.id : "NA",
      name: sessionHelper?.name || "NA",
      email: sessionHelper?.email || "NA",
      phone: sessionHelper?.phone || "NA",
    });

    setShowAnimation(false);
    handleClose();
  }

  const handleProceedClick = () => {
    logEvent("PRESCHOOL_REMINDER_OPTED", {
      courseTypeName: "Preschool",
      userId: sessionHelper.isLoggedIn() ? sessionHelper.id : "NA",
      name: sessionHelper?.name || "NA",
      email: sessionHelper?.email || "NA",
      phone: sessionHelper?.phone || "NA",
    });

    return `https://api.whatsapp.com/send?phone=918035100432&text=Hi, I am interested in renewing Kutuki’s Live Classes for my child, ${sessionHelper?.selectedProfile?.name} and need your help with this.`
  }

  React.useEffect(() => {
    if (containerRef.current && showAnimation) {
       let anim = lottie.loadAnimation({
        path: "https://cdn.kutuki.in/app/payment.json",
        container: containerRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        rendererSettings: {
          progressiveLoad: true,
        },
       });
    }
  }, [showAnimation]);

  React.useEffect(() => {
    if (open)
      setShowAnimation(true)
  }, [open])

  const _calculateRemainingDateRenealDate = () => {
    if (!sessionHelper?.paymentReminder || !Object.keys(sessionHelper?.paymentReminder)?.length)
      return "";
    
    const batchId = Object.keys(sessionHelper?.paymentReminder)[0];

    if (!sessionHelper?.paymentReminder[batchId][0]?.expiryDate)
      return "";

    const today = new Date().getDate();

    const endDate = new Date(sessionHelper?.paymentReminder[batchId][0]?.expiryDate).getDate();

    if((endDate - today > 0) && (endDate - today <=7))
      return `in ${endDate - today} days`;
    
    return "";
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={onCloseClick}
      classes={{ paperScrollPaper: classes.root }}
      maxWidth="sm"
    >
      <CloseIcon className={classes.icons} onClick={onCloseClick} />
      <DialogTitle>
        <Typography
          align="center"
          className={classes.paymentSuccessHeader}
        >
          {sessionHelper?.paymentReminder && Object.keys(sessionHelper?.paymentReminder)?.length > 0
            ? `Your preschool package is due for renewal ${_calculateRemainingDateRenealDate()}`
            : "Please Renew Your Preschool Package"
          }
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.modalContainer}>
        <Box className={classes.imgSection} ref={containerRef} />
        <Button
          className={classes.buttonV1}
          href={handleProceedClick()}
          target="_blank"
          variant="contained"
        >
          Renew Now
        </Button>
      </DialogContent>
      <DialogActions>
        <Box className={classes.actionSection}>
          <Box className={classes.container}>
            <WhatsAppIcon color="primary" style={{ marginRight: 6 }}/>
            <Typography align="center" >  
              <a
                href="https://api.whatsapp.com/send?phone=918035100432&text=Hi, I want to renew Kutuki’s Live Classes for my child and need your help with this."
                target="_blank"
                className={classes.link}
              >
                NEED HELP?
              </a>
            </Typography>
          </Box>
          
          <Typography align="center" >
            <a href="https://app.kutuki.in/live-classes/preschool" target="_blank" className={classes.link}>{`EXPLORE OTHER COURSES`}</a>
          </Typography>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default BatchExpiryStatus;
