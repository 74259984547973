import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import VideoIcon from "@material-ui/icons/Videocam";
import AudioIcon from "@material-ui/icons/Audiotrack";
import DescriptionIcon from "@material-ui/icons/Description";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ImageIcon from "@material-ui/icons/Image";
import ErrorIcon from "@material-ui/icons/Error";
import LaunchIcon from "@material-ui/icons/Launch";
import VideoPlayer from "components/VideoPlayer";
import AudioPlayer from "components/AudioPlayer";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: "90%",
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  dialogContent: {
    width: "100%",
    height: "96%",
    display: "flex",
    margin: "0 auto",
    overflow: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-Between",
    },
  },
  submissionsList: {
    borderRight: `1px solid ${theme.palette.grey["300"]}`,
    height: "100%",
    width: "30%",
    maxHeight: "100%",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      borderRight: `1px solid ${theme.palette.grey["300"]}`,
      height: "30%",
      width: "100%",
      overflowY: "auto",
    },
  },
  content: {
    width: "70%",
    height: "100%",
    paddingLeft: 20,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "70%",
      paddingTop: 20,
      alignItems: "center",
    },
  },
  contentFull: {
    width: "100%",
    height: "100%",
    paddingLeft: 20,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "70%",
      paddingTop: 20,
      alignItems: "center",
    },
  },
  actions: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  selected: {
    color: theme.palette.primary.light,
    fontWeight: "bold",
  },
  fullScreenContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 10000,
    background: "rgba(0, 0, 0, 0.8)",
    padding: 20,
  },
}));

const OPEN_TYPES = ["IMAGE", "DOC", "PDF"];

const PDFViewer = ({ fileURL }) => (
  <iframe
    frameBorder={0}
    title="PDF Viewer"
    height="100%"
    width="100%"
    src={fileURL}
  />
);

const ImageViewer = ({ fileURL }) => (
  <img
    alt="Kutuki File"
    src={fileURL}
    height="100%"
    width="100%"
    style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "scale-down" }}
  />
);

const DocumentViewer = ({ fileURL }) => (
  <iframe
    frameBorder={0}
    title="Document Viewer"
    height="100%"
    width="100%"
    src={`https://docs.google.com/viewer?url=${fileURL}&embedded=true`}
  />
);

const getFileName = (fileURL) =>
  fileURL.substring(fileURL.lastIndexOf("/") + 1, fileURL.lastIndexOf("."));

const getFileIcon = (fileURL) => {
  const extension = fileURL
    ? fileURL.substring(fileURL.lastIndexOf(".") + 1)
    : "other";
  switch (extension.toLowerCase()) {
    case "pdf":
      return <PictureAsPdfIcon style={{ color: "red" }} />;
    case "doc":
    case "docx":
      return <DescriptionIcon style={{ color: "blue" }} />;
    case "jpg":
    case "jpeg":
    case "png":
      return <ImageIcon style={{ color: "grey" }} />;
    case "mp3":
      return <AudioIcon />;
    case "mpg":
    case "mpeg":
    case "mp4":
    case "m3u8":
      return <VideoIcon />;
    default:
      return <ErrorIcon />;
  }
};

function FullScreenContentView({ file, type, handleClose }) {
  const classes = useStyles();

  return (
    <div className={classes.fullScreenContainer} onClick={handleClose}>
      <div className={classes.fullScreenActions}>
        <Button style={{ color: "#FFF" }} onClick={handleClose}>
          CLOSE
        </Button>
      </div>
      {type === "IMAGE" && <ImageViewer fileURL={file} />}
      {type === "PDF" && <PDFViewer fileURL={file} />}
      {type === "DOC" && <DocumentViewer fileURL={file} />}
    </div>
  );
}

function ContentPreview({
  open,
  initialFile = null,
  files = [],
  title,
  onClose,
}) {
  const classes = useStyles();

  const [selected, setSelected] = React.useState(
    files?.[0] || initialFile || {}
  );

  const [fullscreen, setFullscreen] = React.useState(false);

  const videoRef = React.useRef(null);

  const handleClick = (data) => () => setSelected(data);

  React.useEffect(() => {
    setSelected(initialFile || files?.[0] || {});
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        classes={{ paperScrollPaper: classes.dialogPaper }}
      >
        <DialogTitle>
          <Typography variant="h6">{title || "Preview"}</Typography>
          <div className={classes.actions}>
            {OPEN_TYPES.includes(selected.type) && (
              <Button
                color="secondary"
                startIcon={<LaunchIcon />}
                onClick={() => setFullscreen(true)}
              >
                View in FullScreen
              </Button>
            )}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {files.length > 0 ? (
            <div className={classes.dialogContent}>
              {files.length > 1 && (
                <Box className={classes.submissionsList}>
                  <List>
                    {files.map((curr, index) => (
                      <ListItem
                        button
                        className={
                          curr.id === selected.id ? classes.selected : ""
                        }
                        key={index}
                        onClick={handleClick(curr)}
                      >
                        <span style={{ marginRight: 10 }}>
                          {getFileIcon(curr.file)}
                        </span>
                        {curr.name || getFileName(curr.file)}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
              <Box
                className={classes.content}
                className={classNames(classes.content, {
                  [classes.contentFull]: files.length === 1,
                })}
              >
                {selected.type === "VIDEO" && (
                  <VideoPlayer ref={videoRef} contentURL={selected.file} />
                )}
                {selected.type === "AUDIO" && (
                  <AudioPlayer contentURL={selected.file} />
                )}
                {selected.type === "IMAGE" && (
                  <ImageViewer fileURL={selected.file} />
                )}
                {selected.type === "PDF" && (
                  <PDFViewer fileURL={selected.file} />
                )}
                {selected.type === "DOC" && (
                  <DocumentViewer fileURL={selected.file} />
                )}
              </Box>
            </div>
          ) : (
            <Typography variant="h6">No files to preview</Typography>
          )}
        </DialogContent>
      </Dialog>
      {fullscreen && (
        <FullScreenContentView
          file={selected.file}
          type={selected.type}
          handleClose={() => setFullscreen(false)}
        />
      )}
    </>
  );
}

export default ContentPreview;
