import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    zIndex: 5,
  },
}));

const LoadingButton = ({ loading, type, color, label, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {!loading && (
          <Button color={color} type={type} {...props}>
            {label}
          </Button>
        )}
        {loading && <CircularProgress size={24} />}
      </div>
    </div>
  );
};

LoadingButton.defaultProps = {
  loading: false,
  type: "button",
  color: "secondary",
};

LoadingButton.propTypes = {
  ...Button.propTypes,
  loading: PropTypes.bool,
};

export default LoadingButton;
