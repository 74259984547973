import React from "react";

function useClearCache() {
  
  const [loading, setLoading] = React.useState(true);
  const [isLatestVersion, setIsLatestVersion] = React.useState(true);
  
  const refreshCacheAndReload = async () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches?.keys()?.then(async function(names) {
          let counter = 0;
          for (; counter < names.length; counter++)
            await caches?.delete(names[counter]);
        });
    }
    
    // delete browser cache and hard reload
    window.location.reload();
  };
  
  React.useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        
        const currentVersion = localStorage?.getItem("kutParentVersion") || new Date().getTime();
      
        const shouldForceRefresh = latestVersion > currentVersion;
        
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}`);
          
          localStorage.setItem("kutParentVersion", latestVersion);
          
          setIsLatestVersion(false);
        } else {
          console.log(`You already have the latest version - ${latestVersion}`);
          
          setIsLatestVersion(true);
        }
  
        setLoading(false);
      })
      .catch((e) => {
        console.warn("Unable to load meta.json", e.message);
        
        setLoading(false);
      });
  }, [])
  
  return {
    loading,
    isLatestVersion,
    refreshCacheAndReload,
  };
}

export default useClearCache;
