import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import AppShell from "components/AppShell";
import ThemesMobile from "components/lists/ThemesMobile";
import ContentShell from "components/ContentShell";
import ThemeContentMobile from "components/lists/ThemeContentMobile";
import sessionHelper from "services/sessionHelper";

function RhymesAndStories() {
  const location = useLocation();
  const { path } = useRouteMatch();

  return (
    <AppShell>
      {!sessionHelper?.permissions?.themes_mobile?.view ? (
        <ContentShell title="Rhymes & Stories"></ContentShell>
      ) : (
        <Switch location={location}>
          <Route exact path={path}>
            <ThemesMobile />
          </Route>

          <Route path={`${path}/:id/content`}>
            <ThemeContentMobile />
          </Route>
        </Switch>
      )}
    </AppShell>
  );
}

export default RhymesAndStories;
