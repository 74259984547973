import { Client } from "./_clientService";
import sessionHelper from "services/sessionHelper";
import jwt from "jsonwebtoken";
import dayjs from "dayjs";
import { calculatePreschoolExpiry } from "utils/preschoolHelper";

async function saveEnrollment(enrollment) {
  const result = await new Client({
    path: `/users/parents/enroll`,
    payload: {
      name: enrollment.name,
      phone: enrollment.phone,
      email: enrollment.email,
      childName: enrollment.childName,
      age: enrollment.age,
      gender: enrollment.gender || "NA",
    },
  }).post();

  if (result.code !== 200) {
    throw new Error(result.message);
  }
  return result.profile || {};
}

async function addChild(child) {
  const id = sessionHelper.id;
  const result = await new Client({
    path: `/users/parents/${id}/child`,
    payload: {
      ...child,
    },
  }).post();

  if (result.code !== 200) {
    throw new Error(result.message);
  }
  return result.child || {};
}

async function getUserId() {
  const { userId } = await jwt.decode(sessionHelper.token);
  return userId;
}

async function getUser() {
  const userId = await getUserId();
  const result = await new Client({
    path: `/user/${userId}`,
  }).get();

  return {
    id: result.data.id,
    name: result.data?.name,
    firebaseId: result.data?.firebase_id || null,
    phone: result.data?.phone,
    email: result.data?.emailId,
    children: result.data.children.map(child=> ({
        dateOfBirth: child.dob,
        gender: child.gender,
        id: child.id,
        name: child.name,
        parentId: child.parentId,
        languages: child.languages
    }))
} || {};
}

async function gerUserStaticData(childId) {
  const result = await new Client({
    path: `/user/static?${childId ? 'childId=' + childId : ''}`,
  }).get();

  return {
    courses: result.data.courses.map((course) => ({
      ...course,
      courseTypeName: course.courseType.name,
    })),
    batches: result.data.batches.map((batch) => ({
      ...batch,
      startTime: dayjs(batch.timeSlot.startTime).format("hh:mm A"),
      endTime: dayjs(batch.timeSlot.endTime).format("hh:mm A"),
      courseName: batch.course.name,
      courseTypeName: batch.courseType.name,
    })),
    products: result.data?.products || [],
    accessType: result.data.accessType,
    children: result.data?.children || [],
    user: result.data?.user,
    child: result.data?.child,
    permissions: result.data?.permissions,
    userCourseMappings: result.data?.userCourseMappings?.map(mapping => {
      const batch = result.data?.batches.find(batch => batch.id === mapping.batchId);
      return {
        ...mapping,
        id: mapping.id,
        courseId: mapping.courseId,
        batchId: mapping.batchId,
        certificateUrl: mapping.certificateUrl,
        courseName: batch.course.name,
        courseTypeName: batch.courseType.name,
        endDate: batch.endDate,
        endTime: batch.timeSlot.endTime,
        startDate: batch.startDate,
        startTime: batch.timeSlot.startTime,
        status: batch.status.toUpperCase(),
        expiryDate: calculatePreschoolExpiry(mapping.metadata, batch.startDate),
        metadata: typeof mapping.metadata === 'string' ? JSON.parse(mapping.metadata) : mapping.metadata,
      };
    }) || []
  } || {}
}

export { saveEnrollment, addChild, getUser, gerUserStaticData };
