import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import ContentShell from "components/ContentShell";
import AppShell from "../components/AppShell";
import Assignments from "../components/lists/Assignments";
import ViewAssignment from "../components/dialogs/ViewAssignment";
import SubmitAssignment from "components/dialogs/SubmitAssignment";
import AssignmentSubmissions from "components/dialogs/AssignmentSubmissions";
import sessionHelper from "services/sessionHelper";

const AssignmentsTab = () => {
  const location = useLocation();
  const { path } = useRouteMatch();

  const viewAssignment = location?.state?.viewAssignment || false;
  const submitAssignment = location?.state?.submitAssignment || false;
  const viewSubmissions = location?.state?.viewSubmissions || false;

  return (
    <AppShell>
      {!sessionHelper?.permissions?.assignments?.view_instructions ? (
        <ContentShell title="Assignments"></ContentShell>
      ) : (
        <>
          <Switch location={viewAssignment || submitAssignment || location}>
            <Route path={path} children={<Assignments />} />
          </Switch>

          {submitAssignment &&
            <Route
              path={`${path}/:id/submit`}
              children={<SubmitAssignment />}
            />
          }

          {viewAssignment &&
            <Route
              path={`${path}/:id`}
              children={<ViewAssignment />}
            />
          }

          {viewSubmissions &&
            <Route
              path={`${path}/:id/submissions`}
              children={<AssignmentSubmissions />}
            />
          }
        </>
      )}
    </AppShell>
  );
};

export default AssignmentsTab;
