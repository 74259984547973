import React from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useConfirmationDialog} from "components/ConfirmationDialog";
import useSnackbar from "./useSnackbar";
import { saveFilter, getFilterByScreen, resetFilter } from "utils/filterUtils";

const noOp = () => {};

/**
 * @param {Function} getList Async callback to fetch list data
 * @param {Function} deleteList Async callback to delete a list item
 */
const useList = ({ getList, screenName , deleteList = noOp, getListFilters = noOp }) => {
  let filters = getFilterByScreen(screenName);

  const history = useHistory();

  const { path } = useRouteMatch();

  const showSnackbar = useSnackbar();

  const [loading, setLoading] = React.useState(true);

  const [list, setList] = React.useState([]);

  const [pageData, setPageData] = React.useState({});

  const [page, setPage] = React.useState(filters?.page || 1);

  const [pageSize, setPageSize] = React.useState(0);

  const [total, setTotal] = React.useState(0);

  const [conditions, setConditions] = React.useState(filters || {});

  const [filterOptions, setFilterOptions] = React.useState({});

  const { getConfirmation } = useConfirmationDialog();

  const setFilter = (screenName, key, value) => {
    saveFilter(screenName, { [key]: value, page: 1 });
    setPage(1);
    setConditions({ ...conditions, [key]: value });
  };
  
  const setManyFilters = (screenName, newFilters) => {
    const _conditions = { ...conditions };
    
    for (const _filter in newFilters) {
      saveFilter(screenName, { [_filter]: newFilters[_filter], page: 1 });
      _conditions[_filter] = newFilters[_filter];
    }
    
    setConditions(_conditions);
    
    setPage(1);
  }

  const resetFilters = async (screenName) => {
    try {
      setPage(1);
      setConditions({});
      resetFilter(screenName);
    } catch (e) {
      console.log("Error resetting filters");
      showSnackbar(e.message);
    }
  };

  const deleteListItem = async (itemId) => {
    try {
      const deleteConfirmed = await getConfirmation({
        title: "Confirmation",
        message: "Are you sure you want to delete this entry?",
        actionButtonLabel: "Delete",
      });

      if (deleteConfirmed) {
        setLoading(true);

        await deleteList(itemId);

        await fetchData();
      }
    } catch (error) {
      // throwError(error);
    }
    setLoading(false);
  };

  const goToEditor = (id) => {
    history.push(`${path}/${id}`);
  };

  const changePage = (page) => {
    saveFilter(screenName, {page});
    setPage(page);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const {
        data: _list,
        pageData: _pageData = {},
        pageSize,
        totalEntries
      } = await getList({ ...conditions, page });

      setList(_list);

      setPageData(_pageData);

      setPageSize(pageSize);

      setTotal(totalEntries);
    } catch (error) {
      // throwError(error);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    const fetchPageData = async () => {
      const _filterOptions = await getListFilters?.();
      setFilterOptions(_filterOptions?.data);

      await fetchData();
    };

    fetchPageData();
  }, [page, conditions]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    list,
    page,
    pageData,
    pageSize,
    total,
    conditions,
    filterOptions,
    goToEditor,
    changePage,
    deleteListItem,
    fetchData,
    setFilter,
    setManyFilters,
    resetFilters,
  };
};

export default useList;
