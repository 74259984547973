import dayjs from "dayjs";
import _camelCase from "lodash.camelcase";
const shortid = require("shortid");

const uniqueFileName = (originalFileName) => {
  let finalFileName = "";

  const nameArray = originalFileName.split(".");

  const name = nameArray.slice(0, -1).join() + shortid.generate();

  const extension = nameArray.slice(-1).join();

  finalFileName = name + "." + extension;

  return finalFileName;
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function padDate(value) {
  return value < 10 ? "0" + value : value;
}

function formatDateForMessage(timestamp) {
  const date = new Date(timestamp);

  const time = `${padDate(date.getHours())}:${padDate(
    date.getMinutes()
  )}:${padDate(date.getSeconds())}`;

  return `${months[date.getMonth()]} ${padDate(
    date.getDate()
  )}, ${date.getFullYear()} ${time}`;
}

const parseAndFormatTime = (raw) => formatTime(parseTime(raw));

const parseTime = (raw) => raw.split(":");

const formatTime = ([hours, minutes]) =>
  dayjs().hour(hours).minute(minutes).format("HH:mm");

const formatDate = (raw, format) => dayjs(raw).format(format || "MMM DD, YYYY");

const formatTimeTo12HourIST = (raw) => {
  const parsedTime = parseTime(raw);

  return dayjs().hour(parsedTime[0]).minute(parsedTime[1]).format("h:mm A");
};

const formatTime12HoursFormat = ([hours, minutes]) =>
  dayjs().hour(hours).minute(minutes).format("hh:mm A");

function getDateSentForMessage(dateString) {
  const time = new Date(dateString).getTime();
  const currentTime = new Date().getTime();

  const timeElapsed = Math.round((currentTime - time) / 1000);

  if (timeElapsed < 60) return `${timeElapsed} sec(s) ago`;
  else if (timeElapsed < 60 * 60)
    return `${Math.round(timeElapsed / 60)} min(s) ago`;
  else if (timeElapsed < 60 * 60 * 24)
    return `${Math.round(timeElapsed / (60 * 60))} hr(s) ago`;
  else if (timeElapsed < 60 * 60 * 24 * 2) return `Yesterday`;
  else if (timeElapsed < 60 * 60 * 24 * 4)
    return `${Math.round(timeElapsed / (60 * 60 * 24))} day(s) ago`;
  else return formatDateForMessage(dateString).substr(0, 12);
}

const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

const recordingDateFormat = (dateString) => {
  return dayjs(dateString).local().format('ddd, DD MMM')
};

function getAge(dateString) {
  let today = new Date();
  let birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

function filterEnv(filterBy) {
  return Object.keys(process.env)
    .filter(envVar => envVar.includes(filterBy))
    .reduce((_final, envVar) => {
      const key = _camelCase(envVar.replace(filterBy, ''))
      _final[key] = process.env[envVar];
      return _final;
    }, {});
}

export {
  uniqueFileName,
  formatDateForMessage,
  parseAndFormatTime,
  parseTime,
  formatDate,
  formatTime,
  formatTimeTo12HourIST,
  formatTime12HoursFormat,
  getDateSentForMessage,
  isMobile,
  recordingDateFormat,
  padDate,
  getAge,
  filterEnv,
};
