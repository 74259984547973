import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DoneAllRoundedIcon from "@material-ui/icons/DoneAllRounded";
import Linkify from "react-linkify";

const useStyles = makeStyles((theme) => ({
  selfMessageWrapper: {
    padding: "3px",
    marginRight: "4px",
    "&> div": {
      maxWidth: "65%",
      width: "fit-content",
      borderRadius: "16px 0 16px 16px",
      backgroundColor: "#6161610a",
      border: "solid 1px #00000024",
      padding: theme.spacing(1),
      marginLeft: "auto",
    },
  },
  messageWrapper: {
    padding: "3px",
    marginLeft: "4px",
    "&> div": {
      maxWidth: "65%",
      width: "fit-content",
      borderRadius: "0 16px 16px 16px",
      border: "solid 1px transparent",
      backgroundColor: "#86DACD",
      padding: theme.spacing(1),
      marginRight: "auto",
    },
  },
  message: {
    marginBottom: 0,
    textAlign: "left",
    wordBreak: "break-word",
  },
  dateSent: {
    fontSize: "85%",
    letterSpacing: "0.5px",
    marginTop: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

function Message({ body, unread, isSelf, dateSent }) {
  const classes = useStyles();

  return (
    <div
      className={isSelf ? classes.selfMessageWrapper : classes.messageWrapper}
    >
      <div>
        <div className={classes.message}>
          <Typography>
            <Linkify
              properties={{
                target: "_blank",
              }}
            >
              {body}
            </Linkify>
          </Typography>
        </div>
        <div className={classes.dateSent} style={{ textAlign: "right" }}>
          {dateSent}
          {isSelf && (
            <DoneAllRoundedIcon
              fontSize="small"
              color={unread ? "disabled" : "primary"}
            />
          )}
        </div>
      </div>
    </div>
  );
}

Message.propTypes = {
  body: PropTypes.string,
  unread: PropTypes.bool,
  isSelf: PropTypes.bool,
  dateSent: PropTypes.string,
};

Message.defaultProps = {};

export default Message;
