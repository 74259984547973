import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  action: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "20px",
  },
  button: {
    minWidth: "100px",
    color: "white",
  },
}));

function FilterDialog({ handleClose, open, children, handleReset, title }) {
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="sm">
      <Typography variant="h4" style={{ margin: 8 }}>
        {title}
      </Typography>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions className={classes.action}>
        <Button color="secondary" onClick={handleReset}>
          RESET
        </Button>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FilterDialog;
