import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import UnreadMessageCounter from "./messages/UnreadMessageCounter";
import { useConfirmationDialog } from "components/ConfirmationDialog";
import SwitchProfile from "./SwitchProfile";
import { useLoadingOverlay } from "components/LoadingOverlay";
import { drawerWidth } from "./Sidebar";
import { signOut } from "services/authService";
import { getUnreadConversations } from "services/messagesService";
import sessionHelper from "services/sessionHelper";
import logo from "assets/logo.png";
import defaultProfilePicture from "assets/profile.png";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
  },
  drawerPaper: {
    borderRight: "none",
    width: drawerWidth,
    overflowX: "hidden",
  },
  logo: {
    height: 70,
    padding: 5,
    [theme.breakpoints.down("xs")]: {
      height: 50,
    },
  },
  menu: {
    fontSize: 30,
  },
  list: {
    width: 250,
    zIndex: 1,
  },
  toolbar: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
    },
  },
  navLeft: {
    width: drawerWidth,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
    },
  },
  navCenter: {
    display: "flex",
    alignItems: "center",
  },
  navRight: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width:"10%"
    },
    
  },
  navItem: {
    padding: theme.spacing(2),
    color: theme.palette.common.black,
    textDecoration: "none",
    fontSize: 18,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  navItemActive: {
    color: theme.palette.primary.main,
  },
  menuHeader: {
    marginRight: theme.spacing(6),
  },
  menuItem: {
    padding: theme.spacing(2),
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    height: 24,
    width: 16,
    marginRight: 8,
  },
}));

const ProfileMenu = ({ anchorEl, onClose }) => {
  const classes = useStyles();

  const history = useHistory();

  const { getConfirmation } = useConfirmationDialog();

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();

  const handleLogoutClick = async () => {
    onClose();

    const logoutConfirmed = await getConfirmation({
      title: "Confirmation",
      message: "Are you sure you want to logout?",
      actionButtonLabel: "Logout",
    });

    if (logoutConfirmed) {
      showLoadingOverlay();

      await signOut();

      hideLoadingOverlay();
      history.push("/signin");
    }
  };

  const handleProfileClick = () => history.push("/manage-profile");

  return (
    <Menu
      id="profile-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <div className={classes.profileContainer}>
        <CardHeader
          avatar={
            <Avatar
              alt={sessionHelper.name}
              src={sessionHelper?.profilePicture}
            />
          }
          title={sessionHelper.name}
          className={classes.menuHeader}
        />
      </div>
      <Divider className={classes.divider} />
      <MenuItem className={classes.menuItem} onClick={handleProfileClick}>
        Profile
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={handleLogoutClick}>
        Logout
      </MenuItem>
    </Menu>
  );
};

function NavigationBar() {
  const classes = useStyles();

  const childProfile = sessionHelper?.selectedProfile;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [anchorForProfile, setAnchorForProfile] = React.useState(null);

  const [menu, setMenu] = React.useState(false);

  const [unreadConversations, setUnreadConversations] = React.useState(0);

  React.useEffect(() => {
    async function fetchData() {
      const value = await getUnreadConversations();
      setUnreadConversations(value);
    }
    fetchData();
  }, []);

  const toggleMenu = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setMenu(open);
  };

  const handleClick = (event) => {
    setAnchorForProfile(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorForProfile(null);
  };

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);
  
  const customDomain = window?.kutuki?.domain ?? null;

  return (
    <AppBar color="default" position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.navLeft}>
          <Hidden smUp>
            <IconButton onClick={toggleMenu(true)}>
              <UnreadMessageCounter
                value={unreadConversations}
                style={{
                  left: 12,
                  top: 12,
                  fontSize: 0,
                  width: 12,
                  height: 12,
                }}
              />
              <MenuIcon className={classes.menu} color="primary" />
            </IconButton>
          </Hidden>
          <img
            src={["app", "localhost"].includes(customDomain) ? logo : `https://cdn-schools.kutuki.in/logos/${customDomain}.png`}
            alt="Kutuki"
            className={classes.logo}
          />
        </div>
        <Drawer
          variant="persistent"
          anchor="left"
          open={menu}
          classes={{ paper: classes.drawerPaper }}
          onClose={toggleMenu(false)}
          onKeyDown={toggleMenu(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.list} onClick={toggleMenu(false)}>
            <List>
              <ListItem style={{ alignItems: "center" }}>
                <img src={logo} alt="Kutuki" className={classes.logo} />
              </ListItem>

              <ListItem>
                <div
                  style={{ display: "flex" }}
                  className={classes.navItem}
                  onClick={handleClick}
                >
                  <img
                    style={{ height: 32, marginRight: 16 }}
                    src={childProfile?.profilePicture || defaultProfilePicture}
                    alt={"profile pic"}
                  />
                  <Typography variant="body1">{childProfile?.name || ""}</Typography>
                  <KeyboardArrowDownIcon style={{ marginLeft: 16 }} />
                </div>
              </ListItem>

              {sessionHelper?.permissions?.daily_schedule?.view && (
                <ListItem>
                  <NavLink
                    exact
                    to="/"
                    className={classes.navItem}
                    activeClassName={classes.navItemActive}
                  >
                    Daily Schedule
                  </NavLink>
                </ListItem>
              )}
  
              {sessionHelper?.permissions?.themes_mobile?.view && (
                <ListItem>
                  <NavLink
                    to="/rhymes-and-stories"
                    className={classes.navItem}
                    activeClassName={classes.navItemActive}
                  >
                    Rhymes & Stories
                  </NavLink>
                </ListItem>
              )}

              {sessionHelper?.permissions?.learning_materials?.view && (
                <ListItem>
                  <NavLink
                    to="/learning-materials"
                    className={classes.navItem}
                    activeClassName={classes.navItemActive}
                  >
                    Learning Materials
                  </NavLink>
                </ListItem>
              )}

              {sessionHelper?.permissions?.assignments?.view_instructions &&
                (<ListItem>
                  <NavLink
                    to="/assignments"
                    className={classes.navItem}
                    activeClassName={classes.navItemActive}
                  >
                    Assignments
                  </NavLink>
                </ListItem>
                )}

              {sessionHelper?.permissions?.recordings?.view &&
                (<ListItem>
                  <NavLink
                    to="/recordings"
                    className={classes.navItem}
                    activeClassName={classes.navItemActive}
                  >
                    Recordings
                  </NavLink>
                </ListItem>
                )}

              {sessionHelper?.permissions?.teacher_feedback?.view &&
                (<ListItem>
                  <NavLink
                    to={"/messages"}
                    className={classes.navItem}
                    activeClassName={classes.navItemActive}
                  >
                    <Box display="flex">
                      Teacher Feedback
                      <UnreadMessageCounter value={unreadConversations} />
                    </Box>
                  </NavLink>
                </ListItem>
                )}

            </List>
          </div>
        </Drawer>
        <div className={classes.navRight}>
        <Hidden xsDown>
          <div className={classes.navCenter}>
            {sessionHelper?.permissions?.daily_schedule?.view && (
              <NavLink
                exact
                to="/"
                className={classes.navItem}
                activeClassName={classes.navItemActive}
              >
                Daily Schedule
              </NavLink>
            )}
  
            {sessionHelper?.permissions?.themes_mobile?.view && (
              <ListItem>
                <NavLink
                  to="/rhymes-and-stories"
                  className={classes.navItem}
                  activeClassName={classes.navItemActive}
                >
                  Rhymes & Stories
                </NavLink>
              </ListItem>
            )}
            
            {sessionHelper?.permissions?.learning_materials?.view && (
              <ListItem>
                <NavLink
                  to="/learning-materials"
                  className={classes.navItem}
                  activeClassName={classes.navItemActive}
                >
                  Learning Materials
                </NavLink>
              </ListItem>
            )}

            {sessionHelper?.permissions?.assignments?.view_instructions &&
              (<ListItem>
                <NavLink
                  to="/assignments"
                  className={classes.navItem}
                  activeClassName={classes.navItemActive}
                >
                  Assignments
                </NavLink>
              </ListItem>
              )}
            {sessionHelper?.permissions?.recordings?.view && (<NavLink
              to="/recordings"
              className={classes.navItem}
              activeClassName={classes.navItemActive}
            >
              Recordings
            </NavLink>)}
          </div>
        </Hidden>
        <div>
          <Avatar onClick={handleMenuClick} />
        </div>
        </div>
      </Toolbar>
      <ProfileMenu anchorEl={anchorEl} onClose={handleMenuClose} />
      <SwitchProfile anchorEl={anchorForProfile} onClose={handleClose} />
    </AppBar>
  );
}

export default NavigationBar;
