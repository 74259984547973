import React from "react";
import queryString from 'query-string'
import {useParams, Link as RouterLink, useLocation} from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import useList from "hooks/useList";
import ContentShell from "components/ContentShell";
import ContentPreview from "components/ContentPreviewV2";
import Gallery from "components/Gallery";
import {getContentByThemeFirestore} from "services/contentService";
import {SimpleSelectInput} from "../FormSelectInput";
import {makeStyles} from "@material-ui/core/styles";

const screenName = "rhymes-and-stories-content";

const useStyles = makeStyles((theme) => ({
  selectMenu: {
    minWidth: 300,
    [theme.breakpoints.down("xs")]: {
      minWidth: 150,
    },
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer"
  }
}));

function ThemeContent() {
  const { id } = useParams();
  
  const {search} = useLocation();

  const [openContentViewer, setOpenContentViewer] = React.useState(false);

  const [selected, setSelected] = React.useState(null);

  const values = queryString.parse(search);
  
  const classes = useStyles();

  const {
    loading,
    list,
    total,
    pageData,
    page,
    pageSize,
    conditions,
    changePage,
    setFilter,
  } = useList({
     getList: async (filters) => await getContentByThemeFirestore(id, filters),
     screenName
  });

  const handleClick = (contentId) => {
    const content = list.filter(curr => curr.id === contentId)?.[0] || {};
    setSelected(content);
    setOpenContentViewer(true);
  };

  const handleClose = () => {
    setOpenContentViewer(false);
    setSelected(null);
  };

  const themeName = pageData?.theme?.name || "";
  
  const LEARNING_LEVELS = [
    { id: 'nursery', label: 'Nursery' },
    { id: 'lkg', label: 'LKG' },
    { id: 'ukg', label: 'UKG' },
  ];

  return (
    <ContentShell
      loading={loading}
      title={
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/rhymes-and-stories" component={RouterLink} underline="hover">
            <Typography variant="h6" color="primary">Rhymes & Stories</Typography>
          </Link>
          <Typography color="textPrimary">{themeName}</Typography>
        </Breadcrumbs>
      }
      actions={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <SimpleSelectInput
            className={classes.selectMenu}
            fullWidth={true}
            name="learningLevel"
            label="Learning Level"
            value={conditions.learningLevel ?? LEARNING_LEVELS[0].id}
            options={LEARNING_LEVELS}
            onChange={(event) => setFilter(screenName, "learningLevel", event.target.value)}
          />
        </div>
      }
    >
      <Gallery
        list={list}
        currentPage={page}
        pageSize={pageSize}
        totalEntries={total}
        onChangePage={changePage}
        onThumbnailClick={handleClick}
      />
      <ContentPreview
        open={openContentViewer}
        initialFile={selected}
        files={list}
        title={`Rhymes & Stories / ${themeName}`}
        onClose={handleClose}
      />
    </ContentShell>
  );
}

export default ThemeContent;
