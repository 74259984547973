import firebase from "firebase/app";
import "firebase/analytics";

async function logEvent(event, body) {
  // window.Moengage.track_event(event, body);

  firebase.analytics().logEvent(event, body);
}

export { logEvent };
