import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import UnreadMessageCounter from "./UnreadMessageCounter";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflowY: "scroll",
    borderRight: "1px solid #979797",
  },
  conversationItem: {
    backgroundColor: theme.palette.common.white,
    borderBottom: "1px solid #979797",
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    cursor: "pointer",
    position: "relative",
  },
  selectedConversationItem: {
    backgroundColor: theme.palette.grey[300],
    borderBottom: "1px solid #979797",
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    cursor: "pointer",
  },
}));

function ConversationHistory({ currentConversation, conversations, onClick }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {conversations.length ? (
        conversations.map((conversation, index) => (
          <Box
            key={conversation.id}
            width="100%"
            className={
              currentConversation && currentConversation.id === conversation.id
                ? classes.selectedConversationItem
                : classes.conversationItem
            }
            onClick={() => onClick(conversation)}
          >
            <Box style={{ width: 60 }} display="inline-flex">
              <Avatar src={conversation.avatar} />
            </Box>
            <Box
              style={{ width: "calc(100% - 40px" }}
              display="flex"
              flexDirection="column"
            >
              <Typography>{conversation.recipient}</Typography>
              <Typography variant="caption">{conversation.info}</Typography>
            </Box>
            <UnreadMessageCounter value={conversation.studentUnreadCount} />
          </Box>
        ))
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={2}
          m={2}
        >
          <Typography>No Coversations</Typography>
        </Box>
      )}
    </div>
  );
}

ConversationHistory.propTypes = {
  conversations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      recipient: PropTypes.string,
    })
  ),
  currentConversation: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    recipient: PropTypes.string,
  }),
};

ConversationHistory.defaultProps = {
  conversations: [],
};

export default ConversationHistory;
