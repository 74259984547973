import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  unreadCounter: {
    position: "absolute",
    width: 24,
    height: 24,
    left: 10,
    top: 8,
    backgroundColor: "#EF5350",
    color: "#fff",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bolder",
    fontFamily: "auto",
    fontSize: "1rem",
  },
}));

export default function UnreadMessageCounter({ value, ...props }) {
  const classes = useStyles();

  return value > 0 ? (
    <span className={classes.unreadCounter} {...props}>
      {value}
    </span>
  ) : (
    ""
  );
}
