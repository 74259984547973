import {initFirebaseDefaultApp, initFirebaseApp} from "./services/firebaseService";
import rollbarService from "./services/rollbarService";
import {filterEnv} from "./utils/miscUtils";

const defaultAppConfig = filterEnv("REACT_APP_FIREBASE_");
const secondaryAppConfig = filterEnv("REACT_APP_FIREBASE_SECONDARY_");

console.log(secondaryAppConfig);

const appBootstrap = () => {
  initFirebaseDefaultApp(defaultAppConfig);
  initFirebaseApp(secondaryAppConfig, "kutuki-content-store");
  rollbarService.init();
};

export default appBootstrap;
