import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import AppShell from "components/AppShell";
import ConversationShell from "components/messages/ConversationShell";
import Message from "components/messages/Message";
import {
  loadMessages,
  saveMessage,
  readMessages,
  getConversations,
} from "services/messagesService";
import { logEvent } from "services/analyticsService";
import sessionHelper from "services/sessionHelper";

import { formatTime12HoursFormat } from "utils/miscUtils";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Messages() {
  const [loading, setLoading] = React.useState(true);

  const history = useHistory();

  let query = useQuery();

  const [conversations, setConversations] = React.useState([]);

  const [allConversations, setAllConversations] = React.useState([]);

  const [messages, setMessages] = React.useState([]);

  const totalMessage = React.useRef(0);

  const [currentConversation, setCurrentConversation] = React.useState(null);

  const self = React.useMemo(() => {
    return `${sessionHelper?.selectedProfile?.id}`;
  }, []);

  React.useEffect(() => {
    const chatId = query.get("id");

    async function loadConversation() {
      if (conversations.length) {
        const conversation = conversations.find((c) => c.id === chatId);
        if (conversation) {
          if (currentConversation && currentConversation.unsubscribe) {
            currentConversation.unsubscribe();
            currentConversation.unsubscribe = null;
          }

          conversation.unsubscribe = await loadMessages(
            conversation,
            (_messages) => setMessages(_messages)
          );

          setCurrentConversation(conversation);
        }
      } else {
      }
    }
    loadConversation();
  }, [query.get("id"), conversations]);

  React.useEffect(() => {
    const teacherBatchMapping = sessionHelper.batches.reduce((acc, batch) => {
      acc[batch.teacherId.toString()] = batch;
      return acc;
    }, {});

    console.log("Subscribing to chats...");

    const unsubscribe = getConversations(async (conversations) => {
      conversations.forEach((conversation) => {
        const batch = teacherBatchMapping[conversation.staffId];

        if (batch) {
          conversation.info = `${batch.courseTypeName} ${
            batch.courseName
          } (${batch.startTime})`;
        }
      });

      setAllConversations(conversations);

      setLoading(false);
    });

    return () => {
      unsubscribe && typeof unsubscribe === "function" && unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    currentConversation && readMessages(currentConversation, messages);
  }, [currentConversation, messages]);

  React.useEffect(() => {
    if (currentConversation) {
      const tempConversations = [];
      allConversations.forEach((c) => {
        if (c.id !== currentConversation.id) {
          tempConversations.push(c);
        } else {
          currentConversation.staffUnreadCount = c.staffUnreadCount;
          currentConversation.studentUnreadCount = c.studentUnreadCount;
          currentConversation.dateModified = c.dateModified;
        }
      });

      tempConversations.sort((a, b) =>
        a.studentUnreadCount > b.studentUnreadCount ? -1 : 1
      );

      tempConversations.unshift(currentConversation);

      setConversations(tempConversations);
    } else
      setConversations(
        allConversations.sort((a, b) =>
          a.studentUnreadCount > b.studentUnreadCount ? -1 : 1
        )
      );
  }, [allConversations, currentConversation]);

  async function handleClick(conversation) {
    setLoading(true);

    const params = new URLSearchParams();
    if (params.has("id")) params.set("id", conversation.id);
    else params.append("id", conversation.id);

    history.push({ search: params.toString() });

    setLoading(false);
  }

  async function handleSave(message) {
    totalMessage.current = totalMessage.current + 1;

    logEvent("TOTAL_MESSAGES", {
      totalMessages: totalMessage.current,
      lastTeacher: currentConversation.title,
      userId: sessionHelper.isLoggedIn() ? sessionHelper.id : "NA",
    });

    await saveMessage(currentConversation, message);
  }

  return (
    <AppShell>
      <ConversationShell
        currentConversation={currentConversation}
        conversations={conversations}
        currentConversation={currentConversation}
        title={currentConversation?.recipient || ""}
        loading={loading}
        onClick={handleClick}
        onSave={handleSave}
      >
        {messages.map((message, index) => (
          <Message
            key={index}
            body={message.message}
            isSelf={message.senderId === self}
            dateSent={message.dateSent}
            unread={!!message.unread}
          />
        ))}
      </ConversationShell>
    </AppShell>
  );
}

export default Messages;
