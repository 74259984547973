import React from "react";
import { useField } from "formik";
import dayjs from "dayjs";
import DateFnsUtils from "@date-io/date-fns";
import "dayjs/plugin/customParseFormat";
import {DatePicker} from "@material-ui/pickers";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import FormInputShell, {InputShell} from "components/FormInputShell";

const SimpleDateInput = ({ name, value, readOnly, onChange, ...props }) => (
  <InputShell name={name} label={props.label || "Date"}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {readOnly ? (
        <Typography variant="body1" gutterBottom>
          {dayjs(value).format("DD MMM, YYYY")}
        </Typography>
      ) : (
        <DatePicker
          autoOk
          fullWidth
          name={name}
          value={value}
          variant="inline"
          inputVariant="outlined"
          format="dd MMM, yyyy"
          readOnly={readOnly}
          disabled={readOnly}
          onChange={onChange}
          {...props}
        />
      )}
    </MuiPickersUtilsProvider>
  </InputShell>
);

const DateInput = ({ label, readOnly, ...props }) => {
  const [{ onBlur, onChange, ...field }, meta, helper] = useField(props);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {readOnly ? (
        <Typography variant="body1" gutterBottom>
          {dayjs(field.value).format("DD MMM, YYYY")}
        </Typography>
      ) : (
        <DatePicker
          // autoOk
          fullWidth
          variant="inline"
          error={meta.touched && !!meta.error}
          inputVariant="outlined"
          format="dd MMM, yyyy"
          readOnly={readOnly}
          disabled={readOnly}
          onChange={(date) => helper.setValue(date)}
          onClose={() => helper.setTouched(true)}
          {...field}
          {...props}
        />
      )}
      <FormHelperText error={meta.touched && !!meta.error}>
        {meta.touched && meta.error}
      </FormHelperText>
    </MuiPickersUtilsProvider>
  );
};

const FormDateInput = (props) => (
  <FormInputShell label={props.label} name={props.name}>
    <DateInput {...props} />
  </FormInputShell>
);

FormDateInput.propTypes = DatePicker.propTypes;

export default FormDateInput;
export { DateInput, SimpleDateInput };
