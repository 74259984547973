import {Client} from "./_clientService";
import {formatDate, padDate} from "utils/miscUtils";
import dayjs from "dayjs";
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();

export function isMeetingTime(date, timeSlot) {
  const startTime = dayjs(timeSlot?.startTime);
  const endTime = dayjs(timeSlot?.endTime);

  const formattedStartDate = dayjs(date)
    .set("hour", startTime.hour())
    .set("minute", startTime.minute())
    .set("second", startTime.second())
    .subtract(15, "minute");

  const formattedEndDate = dayjs(date)
    .set("hour", endTime.hour())
    .set("minute", endTime.minute())
    .set("second", endTime.second());

  const now = dayjs().unix();

  return now >= formattedStartDate.unix() && now <= formattedEndDate.unix();
}

export function formatData(date, startTime, endTime) {
  const startTimeParts = startTime.split(":");
  const formattedStartDate = dayjs(date).tz("Asia/Kolkata")
    .set("hour", +startTimeParts[0])
    .set("minute", +startTimeParts[1])
    .set("second", +startTimeParts[2]);
  
  const endTimeParts = endTime.split(":");
  const formattedEndDate = dayjs(date).tz("Asia/Kolkata")
    .set("hour", +endTimeParts[0])
    .set("minute", +endTimeParts[1])
    .set("second", +endTimeParts[2]);

  const now = dayjs().unix();
  
  const formattedStartTime = new Date(formattedStartDate.toDate());
  const formattedEndTime = new Date(formattedEndDate.toDate());

  return {
    joinMeeting: now >= formattedStartDate.subtract(15, "minute").unix() && now <= formattedEndDate.add(15, "minute").unix(),
    startTime: `${padDate(formattedStartTime.getHours())}:${padDate(formattedStartTime.getMinutes())}`,
    endTime: `${padDate(formattedEndTime.getHours())}:${padDate(formattedEndTime.getMinutes())}`,
  };
}

async function getDailySchedule({ date, courseId, batchId, childId }) {
  const _date = dayjs(date).format("YYYY-MM-DD");

  let finalPath = `/classcalendar/dailySchedule?`;

  if (date) finalPath += `&date=${_date}`;

  if (courseId) finalPath += `&courseId=${courseId}`;
  
  if (batchId) finalPath += `&batchId=${batchId}`;

  if (childId) finalPath += `&childId=${childId}`;

  const response = await new Client({
    path: finalPath,
  }).get();

  if (response.code !== 200 || !response.ok) {
    throw new Error(
      response?.message || "Uh oh! Unable to get today's schedule"
    );
  }

  const schedule = response.data || [];

  return schedule.map(curr => ({
    ...curr,
    batchId: curr.batchId,
    batchType: curr.batch?.type?.toUpperCase() || "",
    courseId: curr.batch.course.id,
    courseName: curr.batch.course.name,
    courseTypeName: curr.batch.courseType.name,
    module: curr.module || null,
    session: curr.sessionNumber,
    teacherId: curr.batch.teacherId,
    totalSessions: curr.batch.course.sessions,
    date: formatDate(curr.date, "MMM DD, YY"),
    startTime: dayjs(curr.timeSlot.startTime).local().format("hh:mm A"),
    endTime: dayjs(curr.timeSlot.endTime).local().format("hh:mm A"),
    metadata: typeof curr.metadata === 'string' ? JSON.parse(curr.metadata) : curr.metadata,
    joinMeeting: isMeetingTime(curr.date, curr.timeSlot)
  }));
}


async function getCourseTypes() {
  const result = await new Client({
    path: `/courseType`,
  }).get();

  if (result.code !== 200) 
    throw new Error(
      result?.message || "Uh oh! Unable to get course types"
    );
  
  return result?.data?.courseTypes || [];
}

async function getHolidays(years) {
  const result = await new Client({
    path: `classcalendar/holidays?years=${years}`,
  }).get();

  if (result.code !== 200)
    throw new Error(
      result?.message || "Uh oh! Unable to get course types"
    );


  return result.data;
}

export {
  getDailySchedule,
  getCourseTypes,
  getHolidays
};
