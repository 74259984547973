/**
 * _clientService
 * Client for remote service integration
 */
import S3 from "aws-sdk/clients/s3";
import sessionHelper from "services/sessionHelper";
import download from "downloadjs";

const baseUrlInternal = "/api";

const defaultBucket = "kutuki-schools";

const s3 = new S3({
  apiVersion: "2006-03-01",
  region: "ap-south-1",
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
  params: { ACL: "public-read" },
});

/**
 * @param {Object} params
 * @param {string} params.path  path to resource relative to baseUrl
 * @param {string} params.baseUrl  base URL for resource
 * @param {Object} params.options  init options for request
 * @param {Object} params.payload  JSON payload attachment
 */
function Client({
  path = "",
  baseUrl = baseUrlInternal,
  options = {},
  payload,
  headers = {},
}) {
  const _url = (() => {
    let _path = path;

    if (_path[0] !== "/") _path = "/" + _path;

    return baseUrl + _path;
  })();

  const _options = {
    ...JSON.parse(JSON.stringify(options)),
    credentials: "include",
    headers: {
      "Authorization": `Bearer ${sessionHelper.token}`,
      "Content-Type": "application/json",
      "userId": sessionHelper.id,
      "kutuki-application": "kutuki_parent",
      "kutuki-access-token": sessionHelper.token,
      "kutuki-qa-port": process.env.REACT_APP_QA_API_PORT || "",
      ...headers,
    }
  };

  if (payload) {
    _options.body = JSON.stringify(payload);
  }

  const _handleError = (error) => {
    console.error(error);
    throw error;
  };

  const _fetch = async (...args) => {
    try {
      const response = await fetch(...args);
      const responseJson = await response.json();

      return { ok: response.ok, ...responseJson };
    } catch (error) {
      _handleError(error);
    }
  };

  /**
   * GET request to remote service
   */
  this.get = () =>
    _fetch(_url, {
      ..._options,
      method: "GET",
    });

  /**
   * POST request to remote service
   */
  this.post = () =>
    _fetch(_url, {
      ..._options,
      method: "POST",
    });

  /**
   * PUT request to remote service
   */
  this.put = () =>
    _fetch(_url, {
      ..._options,
      method: "PUT",
    });

  /**
   * PATCH request to remote service
   */
  this.patch = () =>
    _fetch(_url, {
      ..._options,
      method: "PATCH",
    });

  /**
   * DELETE request to remote service
   */
  this.delete = () =>
    _fetch(_url, {
      ..._options,
      method: "DELETE",
    });
}

function FileUpload() {
  this.upload = async ({ file, fileName, directory = "" }) => {
    let key = fileName;

    if (key !== "string") {
      key = key.replace(/\s/gi, "_");
    }

    try {
      return await s3
        .upload({
          ContentType: file.type,
          Bucket: defaultBucket,
          Key: `${directory}${key}`,
          Body: file,
        })
        .promise();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}

async function FileDownload(fileURL) {
  // sample file URL https://cdn-schools.kutuki.in/app/content/MG_S6_-Tens_OnesdYBPKOqtDj.jpg;
  const fileName = fileURL.substring(fileURL.lastIndexOf("/") + 1);

  const signedURL = await getSignedUrl(fileURL, {
    ResponseContentDisposition:  `attachment; filename=${fileName}`
  });

  await download(signedURL);
}

async function getSignedUrl(fileURL, options = {}) {
  const key = fileURL.replace("https://cdn-schools.kutuki.in/", "");

  const params = {
    Bucket: defaultBucket,
    Key: key,
    Expires: 3600,
    ...options,
  };

  return s3.getSignedUrlPromise("getObject", params);
}

export {
  Client,
  FileUpload,
  FileDownload,
  getSignedUrl,
};
