import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {useField} from "formik";
import {useDropzone} from "react-dropzone";
import {makeStyles} from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import DeleteIcon from "@material-ui/icons/Delete";
import FormInputShell from "components/FormInputShell";

const useStyles = makeStyles((theme) => ({
  dropZone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(5),
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[50],
    borderStyle: "dashed",
    outline: "none",
    cursor: "pointer",
    transition: "border .24s ease-in-out",
  },
  dropZoneFocus: {
    borderColor: theme.palette.primary.main,
  },
  dropZoneError: {
    borderColor: theme.palette.error.main,
  },
}));

const getFileFromUrl = (url) => ({
  name: url.split("/").slice(-1)[0],
  fileUrl: url,
});

const FormFileInput = ({
  label,
  maxFileCount,
  acceptedFileTypeLabel,
  acceptedFileMimeTypes,
  readOnly,
  ...props
}) => {
  const classes = useStyles();

  const [field, meta, helper] = useField(props);

  const [files, setFiles] = React.useState(
    field.value[0]
      ? typeof field.value[0] === "string"
        ? [getFileFromUrl(field.value[0])]
        : field.value
      : []
  );

  const [dragHover, setDragHover] = React.useState(false);

  const updateFiles = (files) => {
    setFiles(files);
    helper.setValue(files);
  };

  const onDropAccepted = (acceptedFiles) => {
    setDragHover(false);
    helper.setTouched(true);
    helper.setError(null);

    if (maxFileCount === 1) {
      updateFiles(acceptedFiles);
    } else if (files.length < maxFileCount) {
      updateFiles([...files, ...acceptedFiles]);
    } else return;
  };

  const onDropRejected = (e) => {
    helper.setError(`Invalid file type.`);
  };

  const onDragEnter = () => setDragHover(true);

  const onDragLeave = () => setDragHover(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    onDragEnter,
    onDragLeave,
    accept: acceptedFileMimeTypes,
    maxSize: 100000000,
  });

  const removeFile = (index) => {
    const newFiles = [...files];

    newFiles.splice(index, 1);

    setFiles(newFiles);
  };

  return (
    <FormInputShell label={label} name={props.name}>
      {!readOnly && (
        <div
          {...getRootProps({
            className: classnames({
              [classes.dropZone]: true,
              [classes.dropZoneFocus]: !!dragHover,
              [classes.dropZoneError]: meta.touched && !!meta.error,
            }),
          })}
        >
          <input {...getInputProps()} />
          <Typography variant="body1" color="textSecondary">
            Drag some {acceptedFileTypeLabel && ` ${acceptedFileTypeLabel} `}{" "}
            files here, or click to select files
          </Typography>
        </div>
      )}
      <FormHelperText error={meta.touched && !!meta.error}>
        {meta.error}
      </FormHelperText>
      <List dense>
        {files?.map?.((file, index) => (
          <ListItem
            button={typeof file.fileUrl === "string"}
            key={index}
            onClick={
              typeof file.fileUrl === "string"
                ? () => window.open(file.fileUrl, "_blank")
                : undefined
            }
          >
            <ListItemIcon>
              <InsertDriveFileIcon />
            </ListItemIcon>
            <ListItemText primary={file.name} />
            {!readOnly && (
              <ListItemSecondaryAction onClick={() => removeFile(index)}>
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    </FormInputShell>
  );
};

FormFileInput.defaultProps = {
  maxFileCount: 1,
};

FormFileInput.propTypes = {
  label: PropTypes.string,
  maxFileCount: PropTypes.number,
  acceptedFileTypeLabel: PropTypes.string,
  acceptedFileMimeTypes: PropTypes.string,
};

export default FormFileInput;
