import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import SimplePhoneInput from "components/PhoneInput";
import { SimpleTextInput } from "components/FormTextInput";
import {signIn, checkIsStaticOTP, signInWithEmail} from "services/authService";
import useSnackbar from "hooks/useSnackbar";
import Carousel from "components/Carousel";
import tenantConfig from "../tenantConfig";
import logo from "assets/logo.png";
import slide1 from "assets/slide_1.png";
import slide2 from "assets/slide_2.png";
import slide3 from "assets/slide_3.png";
import firebase from "firebase/app";
import "firebase/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    height: 64,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: "center",
  },
  logo: {
    height: 45,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWap: "wrap",
    padding: theme.spacing(2),
    maxWidth: "80%",
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "4px",
  },
  leftContainer: {
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 300,
  },
  authFormRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  phoneNumberContainer: {
    width: "96%",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  formHeader: {
    textAlign: "center",
    margin: theme.spacing(3),
  },
  resendLink: {
    color: "#4285f4",
    textDecoration: "none",
  },
  cancelButton: {
    display: "flex",
    justifyContent: "flex-end",
    margin: 1,
    padding: 2,
  },
  progress: {
    width: "20px",
    height: "20px",
  },
  message: {
    color: "#757575",
    padding: 8,
  },
  emailContainer: {
    width: 400,
  },
  formInput: {
    marginBottom: theme.spacing(2),
    width: "100%",
    maxWidth: 350,
  }
}));

function ResendCountDownTimer(props) {
  const classes = useStyles();
  const [timeLeft, setTimeLeft] = React.useState(30);

  const decrementTime = () => {
    if (timeLeft) {
      setTimeLeft(timeLeft - 1);
    }
  };

  React.useEffect(() => {
    const interval = setInterval(decrementTime, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timeLeft]);

  const onResendClick = (e) => {
    e.preventDefault();
    props.onResendClick();
  };

  return timeLeft > 0 ? (
    <Typography variant="subtitle1">
      {`Resend code in 0:${timeLeft > 9 ? timeLeft : "0" + timeLeft}`}
    </Typography>
  ) : (
    <Typography variant="subtitle1">
      <Link
        href="#"
        onClick={onResendClick}
        variant="body1"
        className={classes.resendLink}
      >
        Resend OTP
      </Link>
    </Typography>
  );
}

function PhoneAuthForm(props) {
  const classes = useStyles();
  
  const history = useHistory();
  
  const showSnackbar = useSnackbar();
  
  const [otpLength] = React.useState(6);
  
  const [values, setValues] = useState({
    OTPSent: false,
    verificationCode: "",
    inProgress: false,
    phone: "",
    isStaticOTP: false,
  });
  
  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      const query = new URLSearchParams(window.location.search);
      try {
        if (user) {
          const userIdToken = await firebase
            .auth()
            .currentUser.getIdToken(true);
          
          await signIn({
            phone:user.phoneNumber,
            firebaseUid:user.uid,
            firebaseToken:userIdToken
          });
          
          let redirectUrl = "/" + (query.get("redirect") || "profiles");
          
          if (query.get("course")) {
            redirectUrl += `?course=${query.get("course")}`;
          }
          
          if (query.get("batch")) {
            redirectUrl += `&batch=${query.get("batch")}`;
          }
          
          history.push(redirectUrl);
        }
      } catch (e) {
        showSnackbar(
          e.message ||
          "Uh oh! Something went wrong during sign in. Please try again"
        );
      }
    });
    
    return () => unsubscribe && unsubscribe();
  }, []);

  const recaptchaVerifier = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptchaContainer",
      {
        size: "invisible",
        callback: () => {},
      }
    );
  };

  const firebaseLoginWithPhone = (phoneNumber) => {
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setValues({
          ...values,
          OTPSent: true,
          inProgress: false,
        });
      })
      .catch((error) => {
        setValues({
          ...values,
          inProgress: false,
        });
        showSnackbar(error.message, "error");
      });
  };

  const handleSend = async (event) => {
    event.preventDefault();
    setValues({
      ...values,
      inProgress: true,
    });

    const phoneNumber = values.phone;
    const isStaticOTPRegistered = await checkIsStaticOTP(phoneNumber);
    if (isStaticOTPRegistered) {
      setValues({
        ...values,
        OTPSent: true,
        inProgress: false,
        isStaticOTP: true,
      });
    } else {
      recaptchaVerifier();
      firebaseLoginWithPhone(phoneNumber);
    }
  };

  const handleVerify = (event) => {
    event.preventDefault();

    setValues({
      ...values,
      inProgress: true,
    });

    // Request for OTP verification
    const { verificationCode, phone, isStaticOTP } = values;
    if (verificationCode.length === otpLength) {
      if (isStaticOTP) {
        const payload = { phone, otp:verificationCode, isStaticOTP };
        signIn(payload)
          .then((result) => {
            setValues({
              ...values,
              OTPSent: false,
              userDetails: result.user,
              phone: "",
              verificationCode: "",
              inProgress: false,
            });
            history.push("/profiles");
          })
          .catch((error) => {
            showSnackbar(error.message, "error");
            setValues({
              ...values,
              inProgress: false,
            });
            props.onError(error);
          });
      } else {
        const confirmationResult = window.confirmationResult;
        confirmationResult
          .confirm(verificationCode)
          .then((result) => {
            const user = result.user;
            setValues({
              ...values,
              OTPSent: false,
              userDetails: user,
              phone: "",
              verificationCode: "",
              inProgress: false,
            });
          })
          .catch(() => {
            showSnackbar("OTP verification failed", "error");
            setValues({
              ...values,
              inProgress: false,
            });
          });
      }
    } else {
      showSnackbar("Please enter a valid OTP");
      setValues({
        ...values,
        inProgress: false,
      });
    }
  };

  const handleEditPhone = (event) => {
    event.preventDefault();

    setValues({
      phone: values.phone,
      OTPSent: false,
      verificationCode: "",
      inProgress: false,
    });
  };

  const resendCode = () => {
    setValues({
      OTPSent: false,
      verificationCode: "",
      inProgress: false,
      phone: "",
      isStaticOTP: false,
    });
  };

  return (
    <Box className={classes.authFormRoot}>
      <form onSubmit={values.OTPSent ? handleVerify : handleSend}>
        <Box className={classes.formHeader}>
          {values.OTPSent ? (
            <>
              <Typography>
                {`Enter the ${otpLength}-digit code we sent to`}
              </Typography>
              <Typography style={{ padding: 16 }}>
                <b>{values.phone}</b>
              </Typography>
            </>
          ) : (
            <h1>Enter your phone number</h1>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            className={classes.phoneNumberContainer}
            display="flex"
            flexDirection="column"
          >
            {values.OTPSent ? (
              <SimpleTextInput
                autoFocus
                variant="outlined"
                size="medium"
                disabled={values.inProgress}
                onChange={(e) =>
                  setValues({ ...values, verificationCode: e.target.value })
                }
                maxLength={otpLength}
                required={values.OTPSent}
                name="OTP"
                id="OTP"
                value={values.verificationCode}
                placeholder={`${otpLength}-digit code`}
              />
            ) : (
              <SimplePhoneInput
                autoFocus
                name="phone"
                label=""
                onPhoneNumberChange={(phone) => setValues({ ...values, phone })}
              />
            )}
          </Box>
          <div id="recaptchaContainer"/>
          <Box width="100%" className={classes.cancelButton}>
            {values.OTPSent && (
              <Button
                style={{ color: "#3f51b5" }}
                size="large"
                onClick={handleEditPhone}
                type="reset"
              >
                Cancel
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              style={{ background: "#86DACD" }}
              disabled={values.inProgress}
              onClick={values.OTPSent ? handleVerify : handleSend}
            >
              {values.inProgress ? (
                <CircularProgress className={classes.progress} />
              ) : values.OTPSent ? (
                "Submit"
              ) : (
                "Verify"
              )}
            </Button>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={2}
            style={{ width: 320 }}
          >
            {values.OTPSent ? (
              <ResendCountDownTimer onResendClick={resendCode} />
            ) : (
              <Typography
                className={classes.message}
                variant="subtitle2"
              >
                By tapping Verify, an SMS may be sent. Message &amp; data rates
                may apply.
              </Typography>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  );
}

function EmailAuthForm() {
  
  const classes = useStyles();
  
  const history = useHistory();
  
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);
  
  const handleEmailChange = (event) => setEmail(event.target.value);
  
  const handlePasswordChange = (event) => setPassword(event.target.value);
  
  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitting(true);
    try {
      await signInWithEmail({ email, password });
      
      history.push('/profiles');
    } catch (e) {
      setSubmitting(false);
    }
  }
  
  return (
    <Box className={classes.authFormRoot}>
      <form onSubmit={handleSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className={classes.emailContainer}
        >
          <Box display="flex" className={classes.formInput}>
            <SimpleTextInput
              autoFocus
              variant="outlined"
              size="medium"
              onChange={handleEmailChange}
              required
              name="email"
              id="email"
              label="Email"
              value={email}
              placeholder="Email"
              readOnly={submitting}
            />
          </Box>
          <div className={classes.formInput}>
            <SimpleTextInput
              variant="outlined"
              size="medium"
              onChange={handlePasswordChange}
              required
              name="password"
              id="password"
              label="Password"
              value={password}
              type="password"
              readOnly={submitting}
            />
          </div>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={submitting}
          >
            Sign In
          </Button>
        </Box>
      </form>
    </Box>
  );
}

function SignIn() {
  const classes = useStyles();
  
  const customDomain = window?.kutuki?.domain ?? null;

  return (
    <main className={classes.root}>
      <div className={classes.container}>
        <div className={classes.leftContainer}>
          <Carousel
            images={[slide1, slide2, slide3]}
            autoplay={true}
            duration={5000}
          />
        </div>
        <div>
          <header className={classes.header}>
            <img
              src={["app", "localhost"].includes(customDomain) ? logo : `https://cdn-schools.kutuki.in/logos/${customDomain}.png`}
              alt="Kutuki"
              className={classes.logo}
            />
          </header>
          <section>
            <Container maxWidth="md">
              <Box style={{ minWidth: 260 }}>
                {tenantConfig?.[customDomain]?.loginMode === "email"
                  ? <EmailAuthForm />
                  : <PhoneAuthForm />
                }
              </Box>
            </Container>
          </section>
        </div>
      </div>
    </main>
  );
}

export default SignIn;
