import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CalenderEventDialog from "components/dialogs/CalendarEventDialog";
import AppShell from "components/AppShell";
import ContentShell from "components/ContentShell";
import BatchExpiryStatus from "components/dialogs/BatchExpiryStatus";
import { getDailySchedule, getHolidays} from "services/coursesService";
import sessionHelper from "../services/sessionHelper";
import { calculateExpiry } from "utils/preschoolHelper";
import dayjs from "dayjs";
import useSnackbar from "hooks/useSnackbar";

const useStyles = makeStyles((theme) => ({
	loadingUiWrapper: {
		width: "100%",
		height: 400,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	calendarItem: {
		fontSize: "1rem",
		fontWeight: 500,
		backgroundColor: "transparent",
		border: "1px solid #eee",
		borderLeft: "5px solid " + theme.palette.primary.main,
		paddingLeft: 5,
		"& .fc-event-title": {
			color: "#000",
		}
	},
	calendarHolidayItem: {
    fontSize: "1rem",
		fontWeight: 500,
		backgroundColor: "transparent",
		border: "1px solid #eee",
		borderLeft: "5px solid " + theme.palette.success.main,
		paddingLeft: 5,
		"& .fc-event-title": {
			color: "#000",
		}
	},
	link: {
    color: theme.palette.primary.main,
    cursor: "pointer"
  }
}));

function Timetable() {
	const classes = useStyles();
	const showSnackbar = useSnackbar();
			
	const [open, setOpen] = React.useState(false);

	const [holidays, setHolidays] = React.useState([]);
	
	const [schedule, setSchedule] = React.useState([]);

	const [isExpired, setIsExpired] = React.useState({expired: false, batchId: -99});
	
	const [selectedEvent, setSelectedEvent] = React.useState({});

	const [openV2, setOpenV2] = React.useState(true);
	
	const [loading, setLoading] = React.useState(true);

	const handleClose = () => setOpenV2(false);
	
	const handleEventClick = async (e) => {
		if (!e?.event?._def?.publicId) return;

		let eventId = JSON.parse(e?.event?._def?.publicId);
		
		const _event = schedule[eventId];
		
		setSelectedEvent(_event);
		
		setOpen(true);
	};
	
	React.useEffect(() => {
		async function fetchData() {
			try {
				setLoading(true);
			
				const productMap = sessionHelper.productMap;
				const userCourseMapping = sessionHelper?.userCourseMappings.find(mapping =>
					Boolean(mapping?.course?.plans?.find(planId => productMap[planId].type === 'preschool'))
				);

				if(userCourseMapping)
					setIsExpired({
						expired: calculateExpiry(userCourseMapping.expiryDate),
						batchId: userCourseMapping?.batchId
					})
				
				const _schedule = await getDailySchedule({ batchId: userCourseMapping.batchId });
				
				const initialYear = new Date(_schedule[0].date).getFullYear();

				const endYear = new Date(_schedule[_schedule.length - 1].date).getFullYear();

				const holidays = await getHolidays([initialYear, endYear].join(","))

				setHolidays(Object.keys(holidays).map((date) => ({
					title: holidays[date],
					date: dayjs(date).format("YYYY-MM-DD"),
					className: classes.calendarHolidayItem
				})))
				
				setSchedule(_schedule.map((item, index) => ({
					id: index,
					materialsRequired: item?.materialsRequired,
					title: item?.metadata?.title || 'Not Available',
					titleV2: item?.metadata?.sessionNumber ? ` Session - ${item?.metadata?.sessionNumber}` : null,
					date: dayjs(item.date).format("YYYY-MM-DD"),
					className: classes.calendarItem
				})));
				
				setLoading(false);
			} catch (e) {
				showSnackbar(e?.message || "Uh oh! can't fetch Time Table.");

				setLoading(false);
			}
		}
		
		fetchData();
	}, []);
	
	return (
		<AppShell drawerOpen={false}>
			<ContentShell title="Time Table" loading={loading}>
				{!isExpired?.expired ? (
					<>
						<FullCalendar
							height={"75vh"}
							aspectRatio={1}
							plugins={[ dayGridPlugin, interactionPlugin ]}
							timeZone="IST"
							eventMinHeight="20"
							eventDisplay="block"
							initialView="dayGridMonth"
							eventClick={(e) => handleEventClick(e)}
							editable={true}
							headerToolbar={{
								left: "prev next",
								center: "title",
								right: "today",
							}}
							events={[...schedule, ...holidays]}
						/>
						<CalenderEventDialog
							open={open}
							handleClose={() => setOpen(false)}
							title={selectedEvent.title}
							titleV2={selectedEvent.titleV2}
							materialsRequired={selectedEvent?.materialsRequired}
						/>
					</>
				) : (
					<>
						<Typography>
							Please <span className={classes.link} onClick={() => setOpenV2(true)}>renew</span> the course to access the learning materials and worksheets.
						</Typography>
						<BatchExpiryStatus
							open={openV2}
							handleClose={handleClose}
							batchId={isExpired?.batchId}
						/>
					</>
				)}
			</ContentShell>
		</AppShell>
	);
}

export default Timetable;
