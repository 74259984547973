import * as firebase from "firebase/app";
import "firebase/firestore";

const _processConfig = (firebaseConfig) => ({
  apiKey: firebaseConfig.apiKey,
  authDomain: firebaseConfig.authDomain,
  databaseURL: firebaseConfig.databaseUrl,
  projectId: firebaseConfig.projectId,
  storageBucket: firebaseConfig.storageBucket,
  messagingSenderId: firebaseConfig.messagingSenderId,
  appId: firebaseConfig.appId,
  measurementId: firebaseConfig.measurementId,
})

const initFirebaseDefaultApp = (firebaseConfig) => firebase.initializeApp(_processConfig(firebaseConfig));

const initFirebaseApp = (firebaseConfig, appName) =>
  firebase.initializeApp(_processConfig(firebaseConfig), appName);

const firestore = (appName) => firebase.app(appName).firestore();

function snapshotToArray(snapshot) {
  const data = [];
  
  snapshot.forEach(doc => data.push(doc.data()));
  
  return data;
}

export { initFirebaseDefaultApp, initFirebaseApp, firestore, snapshotToArray };
