import React from "react";
import {useHistory, useRouteMatch, useParams} from "react-router-dom";
import * as Yup from "yup";
import {EditorState, ContentState, convertFromRaw} from "draft-js";
import useBeforeUnload from "./useBeforeUnload";
import useSnackbar from "./useSnackbar";
import {useLoadingOverlay} from "components/LoadingOverlay";

const getValidationSchema = (type, isRequired) => {
  let validationSchema = {
    date: Yup.date().typeError("Invalid Date."),
    string: Yup.string(),
    file: Yup.mixed(),
    array: Yup.array(),
    object: Yup.object(),
    wysiwyg: Yup.object(),
  }[type];

  if (isRequired) validationSchema = validationSchema.required("Required.");

  return validationSchema;
};

const useFormikConfig = (formConfig) => {
  const [formikConfig, _setFormikConfig] = React.useState({});

  const setFormikConfig = (data = {}) => {
    const _formikConfig = Object.keys(formConfig).reduce(
      (acc, curr) => {
        const fieldConfig = formConfig[curr];

        acc.validationSchema[curr] = getValidationSchema(
          fieldConfig.type,
          fieldConfig.isRequired
        );

        if (fieldConfig.type === "date") {
          acc.initialValues[curr] = data[curr] || null;
        } else if (fieldConfig.type === "array") {
          acc.initialValues[curr] = data[curr] || [];
        } else if (fieldConfig.type === "wysiwyg") {
          acc.initialValues[curr] = data[curr]
            ? EditorState.createWithContent(
              typeof data[curr] === "string"
                ? ContentState.createFromText(data[curr])
                : convertFromRaw(data[curr])
            )
            : EditorState.createEmpty();
        } else {
          acc.initialValues[curr] = data[curr] || "";
        }

        return acc;
      },
      { initialValues: {}, validationSchema: {} }
    );

    _formikConfig.validationSchema = Yup.object(_formikConfig.validationSchema);

    _setFormikConfig(_formikConfig);
  };

  React.useEffect(() => {
    setFormikConfig({});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [formikConfig, setFormikConfig];
};

const useForm = ({
   formConfig: _formConfig,
   isEdit: _isEdit,
   getFormData: _getFormData,
   getFormOptions: _getFormOptions,
   submitForm: _submitForm,
 }) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { id: entryId } = useParams();

  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [formOptions, setFormOptions] = React.useState({});

  const [formikConfig, setFormikConfig] = useFormikConfig(_formConfig);
  const showSnackbar  = useSnackbar();
  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();

  const isEdit =
    typeof _isEdit === "boolean"
      ? _isEdit
      : path.split("/").slice(-1)[0] !== "add";

  useBeforeUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
    return "";
  });

  const goToList = () => {
    const listPath = path.split("/").slice(0, -1).join("/");
    history.push(listPath);
  };

  const submitForm = async (values) => {
    showLoadingOverlay();

    try {
      await _submitForm(values, entryId !== "add" ? entryId : undefined);
      setSubmitting(false);
      goToList();
    } catch (error) {
      console.log(error);
      showSnackbar("Failed to submit form. Please try again.");
    } finally {
      hideLoadingOverlay();
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const dataPromises = [];

        dataPromises.push(_getFormOptions?.());

        if (isEdit) {
          dataPromises.push(_getFormData?.(entryId));
        }

        const [_formOptions, _formData] = await Promise.all(dataPromises);

        setFormOptions(_formOptions?.data);

        setFormikConfig(_formData?.data);
      } catch (error) {
        showSnackbar(error.message);
      }

      setLoading(false);
    };

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    submitting,
    isEdit,
    formOptions,
    formikConfig,
    submitForm,
    goToList,
  };
};

export default useForm;
