export default {
	localhost: {
		sidebarOpen: false,
		loginMode: "email",
	},
	app: {
		sidebarOpen: true,
		loginMode: "phone",
	},
	teachmint: {
		sidebarOpen: false,
		loginMode: "phone",
	},
	csc: {
		sidebarOpen: false,
		loginMode: "email",
	},
	navneet: {
		sidebarOpen: false,
		loginMode: "email",
	},
	rves: {
		sidebarOpen: false,
		loginMode: "email",
	}
}
