import React from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LoadingIndicator from "../LoadingIndicator";
import ConversationHistory from "components/messages/ConversationHistory";
import ScrollToBottom from "react-scroll-to-bottom";

import ReplyContainer from "./ReplyContainer";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    display: "flex",
    flexGrow: 1,
    border: "1px solid #979797",
    overflow: "hidden",
    marginTop: 84,
    height: "88vh",
    width: "99%",
  },
  conversationHistory: {
    flexGrow: 1,
  },
  conversationContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  conversationTitle: {
    background: "#e1f5fe",
    borderBottom: "1px solid #979797",
    padding: theme.spacing(2),
  },
  conversationWithSend: {
    flexGrow: 1,
    overflowY: "auto",
  },
  conversationWithoutSend: {
    flexGrow: 1,
    overflowY: "auto",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ConversationShell({
  disableSend,
  loading,
  currentConversation,
  conversations,
  children,
  title,
  onClick,
  onSave,
}) {
  const classes = useStyles();

  let query = useQuery();

  const chatId = query.get("id");

  return (
    <Box width="100%" height="100%" className={classes.root}>
      <Hidden smUp>
        {!!!chatId && (
          <Box width={1 / 4} className={classes.conversationHistory}>
            <ConversationHistory
              currentConversation={currentConversation}
              conversations={conversations}
              onClick={onClick}
            />
          </Box>
        )}
      </Hidden>
      <Hidden smDown>
        <Box width={1 / 4} className={classes.conversationHistory}>
          <ConversationHistory
            currentConversation={currentConversation}
            conversations={conversations}
            onClick={onClick}
          />
        </Box>
      </Hidden>

      {!!currentConversation && !!chatId ? (
        <Box
          width={3 / 4}
          height="100%"
          className={classes.conversationContainer}
          flexGrow={1}
        >
          <div className={classes.conversationTitle}>
            <Typography align="center">
              <strong>{title}</strong>
            </Typography>
            <Typography align="center" variant="body2">
              {currentConversation?.info || ""}
            </Typography>
          </div>
          <ScrollToBottom
            id="messages"
            className={
              disableSend
                ? classes.conversationWithoutSend
                : classes.conversationWithSend
            }
          >
            {children}
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  bottom: "50%",
                  left: "50%",
                  right: "50%",
                }}
              >
                <LoadingIndicator />
              </div>
            )}
          </ScrollToBottom>
          <ReplyContainer placeholder={`Reply to ${title}`} onSave={onSave} />
        </Box>
      ) : (
        <Hidden smDown>
          <Box
            width={3 / 4}
            height="100%"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5">Start a Conversation !!</Typography>
          </Box>
        </Hidden>
      )}
    </Box>
  );
}

ConversationShell.propTypes = {
  disableSend: PropTypes.bool,
  isLoading: PropTypes.bool,
  history: PropTypes.array,
  children: PropTypes.node,
  title: PropTypes.string,
  currentConversation: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    recipient: PropTypes.string,
  }),
};

ConversationShell.defaultProps = {
  disableSend: false,
  history: [],
};

export default ConversationShell;
