import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Pagination from "components/Pagination";

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    cursor: "pointer",
  },
  thumbnailImage: {
    width: "100%",
    height: 200,
    backgroundColor: theme.palette.grey[100],
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
  thumbnailLabel: {
    display: "inline-block",
    padding: `${theme.spacing(2)}px ${theme.spacing(1.5)}px`,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "100%",
  },
  divider: {
    marginTop: theme.spacing(3),
  },
  mobileThemeThumbnail: {
    cursor: "pointer",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  mobileThemeThumbnailImage: {
    width: "100%",
    height: 200,
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  mobileThemeThumbnailLabel: {
    padding: `${theme.spacing(1.5)}px 0`,
    overflow: "hidden",
    width: 200,
    textAlign: "center",
    fontWeight: "bold",
  },
}));

const Thumbnail = ({ id, imageUrl, label, item, onClick }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={4}>
      <Paper
        className={classes.thumbnail}
        onClick={() => onClick(id, item)}
        title={label}
      >
        <div
          className={classes.thumbnailImage}
          style={{
            backgroundImage: `url('${imageUrl}')`,
          }}
        />
        {label && <Typography
          variant="body1"
          component="span"
          className={classes.thumbnailLabel}
        >
          {label}
        </Typography>}
      </Paper>
    </Grid>
  );
};

const MobileThemeThumbnail = ({ id, imageUrl, label, item, onClick }) => {
  const classes = useStyles();
  
  const metadata = item?.metadata ?? {};
  
  return (
    <Grid item xs={12} sm={4}>
      <div
        className={classes.mobileThemeThumbnail}
        onClick={() => onClick(id, item)}
        title={label}
      >
        <div
          className={classes.mobileThemeThumbnailImage}
          style={{
            backgroundImage: `url('${imageUrl}')`,
          }}
        />
        {label && <Typography
          variant="body1"
          component="span"
          className={classes.mobileThemeThumbnailLabel}
          style={{
            backgroundColor: metadata.textBgColor,
            color: metadata.textColor,
          }}
        >
          {label}
        </Typography>}
      </div>
    </Grid>
  );
};

const Gallery = ({
  list,
  onThumbnailClick,
  currentPage,
  pageSize,
  totalEntries,
  onChangePage,
  thumbnailComponent: ThumbnailComponent
}) => {
  const { divider } = useStyles();
  return (
    <>
      <Grid container spacing={3}>
        {list.map((item) => (
          ThumbnailComponent ? (
              <ThumbnailComponent
                key={item.id}
                label={item.label}
                onClick={onThumbnailClick}
                id={item.id}
                imageUrl={item.thumbnailUrl}
                item={item}
              />
            ) : (
              <Thumbnail
                key={item.id}
                label={item.label}
                onClick={onThumbnailClick}
                id={item.id}
                imageUrl={item.thumbnailUrl}
                item={item}
              />
            )
        ))}
      </Grid>
      <Divider className={divider} />
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        totalEntries={totalEntries}
        onChangePage={onChangePage}
        rows={list}
      />
    </>
  );
};

Gallery.defaultProps = {
  onThumbnailClick: () => {},
};

Gallery.propTypes = {
  onThumbnailClick: PropTypes.func,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      imageUrl: PropTypes.string,
    })
  ),
};

export default Gallery;
export {
  MobileThemeThumbnail,
}
