import React from "react";
import PropTypes from "prop-types";
import videojs from "video.js";
import "video.js/dist/video-js.css";

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        autoplay: false,
        controls: true,
        fluid: true,
      },
    };
  }

  getVideoOptions = (contentURL) => {
    const isHLS = (contentURL || "").indexOf("m3u8") > -1;
    const type = isHLS ? "application/x-mpegURL" : "video/mp4";

    const options = {
      autoplay: false,
      controls: true,
      fluid: true,
      sources: [{ src: contentURL, type }],
    };

    if (isHLS) {
      options.hls = { bandwidth: 540 };
    } else {
      delete options.hls;
    }

    return options;
  };

  componentDidMount() {
    // instantiate Video.js
    const options = this.getVideoOptions(this.props.contentURL);
    this.player = videojs(this.videoNode, options);
    this.setState(options);
  }

  componentDidUpdate(prevProps, prevState, _snapshot) {
    const prevSrc = prevProps.contentURL;
    const currentSrc = this.props.contentURL;

    if (prevSrc && prevSrc !== currentSrc) {
      const options = this.getVideoOptions(currentSrc);

      this.player.src(options.sources);

      if (prevState.options.fluid !== options.fluid) {
        this.player.fluid(options.fluid);
      }

      this.setState(options);
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div style={{ height: "90%"}}>
        <div data-vjs-player >
          <video
            ref={(node) => (this.videoNode = node)}
            className="video-js vjs-big-play-centered"
          />
        </div>
      </div>
    );
  }
}

VideoPlayer.propTypes = {
  contentURL: PropTypes.string,
};

VideoPlayer.defaultProps = {
  contentURL: "",
};

export default VideoPlayer;
