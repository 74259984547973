import Rollbar from "rollbar";

class RollbarService {
  constructor() {
    this.instance = null
  }
  
  init() {
    this.instance = new Rollbar({
      enabled: !(process.env.NODE_ENV === "development"),
      accessToken: '40d2d6dbb96f490b8fa750bc3c37c372',
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: process.env.NODE_ENV === "development" ? "local" : process.env.REACT_APP_STAGE,
      }
    });
  }
}

export default new RollbarService();