import React from "react";
import queryString from 'query-string'
import {useParams, Link as RouterLink, useLocation} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import useList from "hooks/useList";
import ContentShell from "components/ContentShell";
import ContentPreview from "components/ContentPreview";
import Gallery from "components/Gallery";
import {getContentByTheme} from "services/contentService";

const screenName = "learning-content";

function ThemeContent() {
  const { id } = useParams();
  
  const {search} = useLocation();

  const [openContentViewer, setOpenContentViewer] = React.useState(false);

  const [selected, setSelected] = React.useState(null);

  const values = queryString.parse(search);

  const {
    loading,
    list,
    total,
    pageData,
    page,
    pageSize,
    changePage,
    resetFilters,
  } = useList({
     getList: async ({ page }) => await getContentByTheme(id, { page,
      courseId: values?.courseId || -99,
      subCourseId: values?.subCourseId || -99 }),
     screenName
});

  const handleClick = (contentId) => {
    const content = list.filter(curr => curr.id === contentId)?.[0] || {};
    setSelected(content);
    setOpenContentViewer(true);
  };

  const handleClose = () => {
    setOpenContentViewer(false);
    setSelected(null);
  };


  const themeName = pageData?.theme?.name || "";

  return (
    <ContentShell
      loading={loading}
      title={
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/learning-materials" component={RouterLink} underline="hover">
            <Typography variant="h6" color="primary">Learning Materials</Typography>
          </Link>
          <Typography color="textPrimary">{themeName}</Typography>
        </Breadcrumbs>
      }
    >
      {list.length === 0 && <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: 20,
        }}
      >
        <Typography>No learning materials mapped to selected course</Typography>
        <Button
          color="primary"
          onClick={() => resetFilters(screenName)}
        >
          Reset filters?
        </Button>
      </div>}
      <Gallery
        list={list}
        currentPage={page}
        pageSize={pageSize}
        totalEntries={total}
        onChangePage={changePage}
        onThumbnailClick={handleClick}
      />
      <ContentPreview
        open={openContentViewer}
        initialFile={selected}
        files={list}
        title={`Learning Materials / ${themeName}`}
        onClose={handleClose}
      />
    </ContentShell>
  );
}

export default ThemeContent;
