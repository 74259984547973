import React from "react";
import PropTypes from "prop-types";
import {useField} from "formik";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select/Select";
import Typography from "@material-ui/core/Typography";
import FormInputShell from "./FormInputShell";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(current, values = [], theme) {
  return {
    color:
      values.indexOf(current) === -1
        ? theme.palette.common.black
        : theme.palette.primary.main,
  };
}

const MultipleSelectInput = ({ options, readOnly, ...props }) => {
  const theme = useTheme();

  const classes = useStyles();

  const [field, meta] = useField(props);

  return (
    <>
      {readOnly ? (
        <Typography variant="body1" gutterBottom>
          {options.find((option) => option.id === field.value)?.label}
        </Typography>
      ) : (
        <Select
          style={{ border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: 4, minHeight: 54 }}
          disabled={readOnly}
          variant="outlined"
          fullWidth
          multiple
          error={meta.touched && !!meta.error}
          {...field}
          {...props}
          input={<Input
            id="select-multiple-chip"
            style={{ borderBottom: 0 }}
          />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              style={getStyles(option.id, field.value, theme)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
      <FormHelperText error={meta.touched && !!meta.error}>
        {meta.touched && meta.error}
      </FormHelperText>
    </>
  );
};

const FormMultipleSelectInput = ({ label, ...props }) => (
  <FormInputShell label={label} name={props.name}>
    <MultipleSelectInput {...props} />
  </FormInputShell>
);

FormMultipleSelectInput.defaultProps = {
  options: [],
};

FormMultipleSelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  ...Select.propTypes,
};

export default FormMultipleSelectInput;
export { MultipleSelectInput };
