import React from "react";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import NavigationBar from "./NavigationBar";
import Sidebar, {drawerWidth} from "./Sidebar";
import tenantConfig from "../tenantConfig";

const useStyles=  makeStyles(theme => ({
  content: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 30,
    maxWidth: "100%",
    maxHeight: "100%",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    }
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth + 30,
  },
}));

function AppShell({ drawerOpen = true, children }) {
  const classes = useStyles();
  
  const [open, setOpen] = React.useState(drawerOpen);
  
  React.useEffect(() => {
    const setResponsiveness = () => {
      const domain = window?.kutuki?.domain ?? '';
      const config = tenantConfig?.[domain] ?? { sidebarOpen: true };
      setOpen(drawerOpen && config.sidebarOpen);
    }
  
    setResponsiveness();
    
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const handleDrawerOpen = () => setOpen(!open);

  return (
    <>
      <NavigationBar />
      <Hidden xsDown>
        <Sidebar open={open} onToggleOpen={handleDrawerOpen} />
      </Hidden>
      <main
        className={classNames({
          [classes.content]: true,
          [classes.contentShift]: open,
        })}
      >
        {children}
      </main>
    </>
  );
}

AppShell.propTypes = {};

AppShell.defaultProps = {};

export default AppShell;
