import React from "react";
import {useHistory} from "react-router-dom";
import LoadingIndicator from "components/LoadingIndicator";
import {signOut} from "services/authService";

function SignOut() {
  const history = useHistory();
  
  React.useEffect(() => {
    signOut()
      .then(() => history.push("/signin"))
      .catch(e => history.push("/signin"))
  }, []);
  
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingIndicator />
    </div>
  );
}

export default SignOut;
