import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DescriptionIcon from "@material-ui/icons/Description";
import useList from "hooks/useList";
import BatchExpiryStatus from "components/dialogs/BatchExpiryStatus";
import { SimpleSelectInput } from "components/FormSelectInput";
import Table, { TableShell } from "components/Table";
import { getAssignments } from "services/assignmentsService";
import { logEvent } from "services/analyticsService";
import sessionHelper from "services/sessionHelper";
import Pagination from "../Pagination";
import { calculateExpiry, canAccessAssignment } from "utils/preschoolHelper";

const screenName = "assignment";

const useStyles = makeStyles((theme) => ({
  selectMenu: {
    minWidth: 300,
    [theme.breakpoints.down("xs")]: {
      minWidth: 150,
    },
  },
  card: {
    width: "100%",
    minHeight: 180,
    border: "1px solid",
    borderColor: "#e6e6e6",
    borderRadius: 8,
    margin: 5,
    padding: 15,
  },
  data: {
    display: "flex",
    justifyContent: "center",
    fontSize: "1.8em",
    fontWeight: 800,
    marginBottom: 16,
    marginTop: 10,
  },
  action: {
    display: "flex",
    justifyContent: "center",
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer"
  }
}));

function RowActions({ rowId, data, hideButtonText = false }) {
  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = React.useState(false);

  const [batchId, setBatchId] = React.useState(-99);

  const handleClose = () => setOpen(false);

  const totalInstructionClick = React.useRef(0);

  const handleViewAssignment = () => {
    totalInstructionClick.current = totalInstructionClick.current + 1;
    logEvent("INSTRUCTION_CLICK", {
      totalClicks: totalInstructionClick.current,
      courseTypeId: data.courseTypeId,
      courseId: data.courseId,
      courseTypeName: data.courseTypeName,
      courseName: data.courseName,
      id: data.id,
      session: data.session,
      title: data.title,
      userId: sessionHelper.isLoggedIn() ? sessionHelper.id : "NA",
    });

    const dataV2 = canAccessAssignment(data?.courseId);

    if (!dataV2.canView) {
      setBatchId(dataV2.batchId);
      setOpen(true);
      return;
    }

    history.push(`/assignments/${rowId}`, { viewAssignment: location });
  };

  const handleSubmit = () => {
    const dataV2 = canAccessAssignment(data?.courseId);
    if (!dataV2.canView) {
      setBatchId(dataV2.batchId);
      setOpen(true);
      return;
    }

    history.push(`/assignments/${rowId}/submit`, {
      submitAssignment: location,
    });
  };

  const handleViewSubmissions = () => {
    const dataV2 = canAccessAssignment(data?.courseId);
    if (!dataV2.canView) {
      setBatchId(dataV2.batchId);
      setOpen(true);
      return;
    }
    history.push(`/assignments/${rowId}/submissions`, {
      viewSubmissions: location,
    });
  }
    

  return (
    <div style={{ display: "flex" }}>
      {sessionHelper?.permissions?.assignments?.view_instructions && (
        <Button
          color="primary"
          startIcon={<VisibilityIcon />}
          onClick={handleViewAssignment}
        >
          {!hideButtonText && "Instructions"}
        </Button>
      )}
      
      {sessionHelper?.permissions?.assignments?.submit_assignment && (
        <Button
        color="primary"
        style={{ marginLeft: 30 }}
        startIcon={<CloudUploadIcon />}
        onClick={handleSubmit}
        >
          {!hideButtonText && "Submit"}
        </Button>
      )}
      {sessionHelper?.permissions?.assignments?.view_submissions && (
        <Button
          color="primary"
          style={{ marginLeft: 30 }}
          startIcon={<DescriptionIcon />}
          onClick={handleViewSubmissions}
        >
          {!hideButtonText && "Submissions"}
        </Button>
      )}
       <BatchExpiryStatus
        open={open}
        handleClose={handleClose}
        batchId={batchId}
      />
    </div>
  );
}

const AssignmentsMobileView = ({ rows, currentPage, pageSize, totalEntries, onChangePage }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {rows.map((row, index) => (
        <Box className={classes.card}>
          <Typography variant="h4" align="center">
            {row.courseTypeName} {row.courseName}
          </Typography>
          <Box className={classes.data}>{`Session - ${row.session}`}</Box>
          <Box className={classes.data}>{row.title}</Box>
          <Box className={classes.action}>
            <RowActions rowId={row.id} data={row} hideButtonText={true}/>
          </Box>
        </Box>
      ))}
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        totalEntries={totalEntries}
        onChangePage={onChangePage}
      />
    </Box>
  );
};

function Assignments() {
  const [modules, setModules] = React.useState([]);
  
  const {
    loading,
    list,
    page,
    pageSize,
    total,
    conditions,
    changePage,
    setFilter,
    setManyFilters,
  } = useList({
    getList: getAssignments,
    screenName,
  });

  const classes = useStyles();

  const [open, setOpen] = React.useState(true);

  const showPaymentDialog = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const _getBatchInformation = (course) => {
    const batch = sessionHelper?.batches?.find((data) => data.courseId === course.id);

    return {
      isExpired: course?.courseType === "ONLINE_PRESCHOOL"
        ? calculateExpiry(batch.expiryDate)
        : false,
      batchId: batch?.id
    }
  }

  const courses = React.useMemo(() => {
    const _courses = (sessionHelper?.courses || []).map((course) => ({
      id: course.id,
      label: `${course.courseTypeName} - ${course.name}`,
      courseType: course.courseType,
      batchDetails: _getBatchInformation(course)
    }));

    const _coursesV2 = _courses.filter((data) => data.courseType !== "DIGITAL_RECORDING")

    return [{ id: -99, label: "All Courses" }, ..._coursesV2];
  }, []);
  
  function _setModules(selectedCourseId) {
    const course = (sessionHelper.courses || []).filter(_course => _course.id === selectedCourseId);
    
    if (course?.[0]?.metaData?.modules) {
      setModules(
        course[0].metaData.modules.map(curr => ({ id: curr.code, label: curr.name }))
      );
    } else {
      setModules([]);
    }
  }
  
  function handleCourseSelect(event) {
    const course = (sessionHelper.courses || []).filter(_course => _course.id === event.target.value);
    
    setManyFilters(screenName, {
      courseId: event.target.value,
      subCourseId: course?.[0]?.metaData?.modules ? course?.[0]?.metaData?.modules[0].code : -99,
    });
  }
  
  React.useEffect(() => {
    if (!loading) {
      _setModules(conditions.courseId);
    }
  }, [loading])

  return (
    <TableShell
      loading={loading}
      title={"Assignments"}
      actions={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <SimpleSelectInput
            className={classes.selectMenu}
            fullWidth={true}
            name="courseId"
            label="Courses"
            value={conditions.courseId || -99}
            options={courses}
            onChange={handleCourseSelect}
          />
          {modules.length > 0 && <SimpleSelectInput
            className={classes.selectMenu}
            style={{ marginLeft: 5 }}
            fullWidth={true}
            name="subCourseId"
            label="Module"
            value={conditions.subCourseId || -99}
            options={modules}
            onChange={(event) => setFilter(screenName, "subCourseId", event.target.value)}
          />}
        </div>
      }
    >
      {!courses?.find((course) => course.id === conditions.courseId)?.batchDetails?.isExpired ? (
        <>
          <Hidden xsDown>
          <Table
            columns={[
              { id: "courseTypeName", label: "Course Type" },
              { id: "courseName", label: "Course" },
              { id: "session", label: "Session" },
              { id: "title", label: "Assignment Title" },
              { id: "actions", label: "Action(s)" },
            ]}
            data={list}
            actions={RowActions}
            currentPage={page}
            pageSize={pageSize}
            totalEntries={total}
            onChangePage={changePage}
          />
          </Hidden>
          <Hidden smUp>
            <AssignmentsMobileView
              rows={list}
              currentPage={page}
              pageSize={pageSize}
              totalEntries={total}
              onChangePage={changePage}
            />
          </Hidden>
        </>
      ) : (
        <>
          <Typography>
            Please <span className={classes.link} onClick={showPaymentDialog}>renew</span> the course to access the learning materials and worksheets.
          </Typography>
          <BatchExpiryStatus
            open={open}
            handleClose={handleClose}
            batchId={courses?.find((course) => course.id === conditions.courseId)?.batchDetails?.batchId}
          />
        </>
      )}
      
    </TableShell>
  );
}

export default Assignments;
