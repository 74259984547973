import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    minWidth: "200px",
    alignItems: "center"
  },
  message: {
    marginBottom: 16
  }
}));

const LoadingOverlayContext = React.createContext({
  _loading: false,
  _message: '',
  _setMessage: () => {},
});

const LoadingOverlay = () => {
  const classes = useStyles();
  const { _loading, _message } = React.useContext(LoadingOverlayContext);

  return (
    <Backdrop className={classes.backdrop} open={_loading}>
      <Box className={classes.content}>
        <Typography variant="h6" className={classes.message}>
          {_message}
        </Typography>
        <CircularProgress color="primary" />
      </Box>
    </Backdrop>
  );
};

const LoadingOverlayProvider = ({ children }) => {
  const [_loading, _setLoading] = React.useState(false);
  const [_message, _setMessage] = React.useState('');

  return (
    <LoadingOverlayContext.Provider value={{ _loading, _setLoading, _message, _setMessage }}>
      <LoadingOverlay />
      {children}
    </LoadingOverlayContext.Provider>
  );
};

const useLoadingOverlay = () => {
  const { _setLoading, _setMessage } = React.useContext(LoadingOverlayContext);

  const showLoadingOverlay = (message = '') => {
    _setMessage(message);
    _setLoading(true);
  }

  const hideLoadingOverlay = () => {
    _setMessage('');
    _setLoading(false);
  }

  return { showLoadingOverlay, hideLoadingOverlay };
};

export default LoadingOverlay;
export { LoadingOverlayProvider, useLoadingOverlay };
