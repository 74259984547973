import React from "react";
import {
  BrowserRouter,
  Route,
  Redirect,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import useClearCache from "hooks/useClearCache";
import LoadingOverlay, {LoadingOverlayProvider} from "components/LoadingOverlay";
import { ConfirmationDialogProvider } from "components/ConfirmationDialog";
import SignIn from "./SignIn";
import ProfileSelector from "./ProfileSelector";
import MyClasses from "./MyClasses";
import DailySchedule from "./DailySchedule";
import Assignments from "./Assignments";
import LearningMaterials from "./LearningMaterials";
import RhymesAndStories from "./RhymesAndStories";
import ManageProfile from "./ManageProfile";
import ClassRecordings from "./ClassRecordings";
import Messages from "./Messages";
import SignOut from "./SignOut";
import TimeTable from "./TimeTable";
import NotFound from "./NotFound";
import sessionHelper from "services/sessionHelper";
import { restoreSession, signOut } from "../services/authService";
import theme from "../theme";

const muiTheme = createMuiTheme(theme);

const allowedRoutesForWorkSheetAndRecordingUser = ["/assignments", "/learning-materials", "/manage-profile"]

const PrivateRoute = ({ path, component: Component, exact }) => {
  const isLoggedIn = sessionHelper.isLoggedIn();

  const canRoute = allowedRoutesForWorkSheetAndRecordingUser.includes(path);

  const isForceRedirect =  sessionHelper?.isOnlyWorkSheetUser || sessionHelper?.isOnlyRecordingUser;

  if (isLoggedIn) {
    if (isForceRedirect) {
      if (canRoute) return <Route exact={exact} path={path} component={Component} />

      return sessionHelper?.isOnlyWorkSheetUser ? <Redirect to="/assignments" /> : <Redirect to="/learning-materials" />
    } else {
      return <Route exact={exact} path={path} component={Component} />
    }
  } else {
    return <Redirect to="/signin" />
  }
};

const subMinutes = function (dt, minutes) {
  return new Date(dt.getTime() - minutes * 60000);
};

function SessionSync({ children }) {
  const history = useHistory();

  const location = useLocation();

  const [loading, setLoading] = React.useState(true);

  const isTokenExpiring = () =>
    subMinutes(new Date(sessionHelper.tokenExpiry), 5) <= new Date();

  const isRefreshTokenExpiring = () =>
    subMinutes(new Date(sessionHelper.refreshTokenExpiry), 5) <= new Date();

  const goToSignIn = async () => {
    await signOut();

    history.push(
      location.pathname.indexOf("live-classes") > -1
        ? location.pathname
        : "/signIn"
    );
  };

  async function onLoad() {
    console.log("onLoad");

    try {
      // if the refresh token is expiring then sign out and redirect to sign in
      const kutRefreshTokenExpiry = localStorage.getItem(
        "kutRefreshTokenExpiry"
      );

      if (kutRefreshTokenExpiry) {
        console.log("kutRefreshTokenExpiry", kutRefreshTokenExpiry);
        sessionHelper.refreshTokenExpiry =
          new Date(kutRefreshTokenExpiry).getTime() || new Date().getTime();
      }

      if (isRefreshTokenExpiring()) {
        console.log("refresh token expiring", sessionHelper.refreshTokenExpiry);
        await goToSignIn();
      }

      if (sessionHelper.isLoggedIn()) {
        if (isTokenExpiring())
          sessionHelper.token = null;
        
        await restoreSession();
      }

      console.log(`onLoad -> session restore check complete`);
    } catch (e) {
      console.error(`onLoad -> error attempting to restore session`, e);
      await goToSignIn();
    }
  }

  async function setupRefresh() {
    try {
      if (isRefreshTokenExpiring()) {
        await goToSignIn();
      }

      if (sessionHelper.isLoggedIn()) {
        if (isTokenExpiring())
          sessionHelper.token = null;

        await restoreSession();
      }
    } catch (e) {
      console.error("setupTokenRefresh -> error refreshing token", e.messgae);
      await goToSignIn();
    }
  }

  React.useEffect(() => {
    onLoad()
      .then(() => setLoading(false))
      .catch((e) => setLoading(false));

    const timerId = setInterval(setupRefresh, 3600000);

    return () => clearInterval(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? <LoadingOverlay /> : <>{children}</>;
}

const Screens = () => {
  const { isLatestVersion, loading, refreshCacheAndReload } = useClearCache();

  React.useEffect(() => {
    if (!loading && !isLatestVersion) {
      refreshCacheAndReload()
        .catch((e) => console.warn(e?.message));
    }
  }, [loading, isLatestVersion]);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <LoadingOverlayProvider>
        <SnackbarProvider maxSnack={7}>
          <ConfirmationDialogProvider>
            {!loading ? (
              <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
                <SessionSync>
                  <Switch>
                    <Route path="/signin" component={SignIn} />
                    <Route path="/profiles" component={ProfileSelector} />
                    <PrivateRoute path="/" exact component={DailySchedule} />
                    <PrivateRoute path="/learning-materials" component={LearningMaterials} />
                    <PrivateRoute path="/rhymes-and-stories" component={RhymesAndStories} />
                    <PrivateRoute path="/assignments" component={Assignments} />
                    <PrivateRoute path="/manage-profile" component={ManageProfile} />
                    <PrivateRoute path="/my-classes" component={MyClasses} />
                    <PrivateRoute path="/messages" component={Messages} />
                    <PrivateRoute path="/recordings" component={ClassRecordings} />
                    <PrivateRoute path="/timetable" component={TimeTable} />
                    <Route path="/signout" component={SignOut} />
                    <Route path="*" component={NotFound} />
                  </Switch>
                </SessionSync>
              </BrowserRouter>
            ) : null}
          </ConfirmationDialogProvider>
        </SnackbarProvider>
      </LoadingOverlayProvider>
    </MuiThemeProvider>
  );
};

export default Screens;
