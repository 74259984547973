import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import AppShell from "components/AppShell";
import EditProfile from "components/forms/EditProfile";

function ManageProfiles() {
  const location = useLocation();
  const { path } = useRouteMatch();

  return (
    <AppShell>
      <Switch location={location}>
        <Route path={path}>
          <EditProfile />
        </Route>
      </Switch>
    </AppShell>
  );
}

export default ManageProfiles;
