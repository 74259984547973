import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ContentShell from "components/ContentShell";
import useSnackbar from "hooks/useSnackbar";
import { createSession, signOut } from "services/authService";
import sessionHelper from "services/sessionHelper";
import kutukiLogo from "assets/logo.png";
import { useLoadingOverlay } from "components/LoadingOverlay";
import logo from "../assets/logo.png";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff",
  },
  toolbar: {
    justifyContent: "center",
  },
  logo: {
    height: 75,
    padding: 5,
  },
  listItem: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

function ProfileSelector() {
  const classes = useStyles();

  const history = useHistory();
  
  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();

  const showSnackbar = useSnackbar();

  const [profiles] = React.useState(sessionHelper.childProfiles || []);

  const [displayPage, setDisplayPage] = React.useState(false);

  const handleProfileClick = (childId, parentId) => async () => {
    try {
      showLoadingOverlay("Setting up the platform for you...");
      localStorage.setItem("kutProfile", JSON.stringify({
        id: parentId,
        childId,
      }));

      await createSession(childId);

      sessionHelper.isOnlyWorkSheetUser ? history.push("/assignments") : history.push("/");
    } catch (e) {
      showSnackbar(e.message);
    } finally {
      hideLoadingOverlay();
    }
  };

  const handleSignOutClick = async () => {
    try {
      await signOut();

      history.push("/signin");
    } catch (e) {
      showSnackbar("Uh Oh! Something went wrong signing out.");
    }
  };
  
  function determinePostLoginScreen() {
    const permissions = sessionHelper.permissions;
    if (permissions.daily_schedule.view) {
      return '/';
    } else if (permissions.themes_mobile.view) {
      return '/rhymes-and-stories';
    } else if (permissions.learning_materials.view) {
      return '/learning-materials';
    } else if (permissions.assignments.view_instructions) {
      return '/assignments';
    } else {
      return '/profile';
    }
  }
  
  React.useEffect(() => {
    async function init() {
      // get the selected profile from the localStorage
      const kutProfile = localStorage.getItem("kutProfile");
      
      if (kutProfile) {
        const profile = JSON.parse(kutProfile);
        
        if (profile.id !== sessionHelper.id) {
          localStorage.removeItem("kutProfile");
          history.push("/signout");
        }

        // creating the session for the childId in localStorage
        try {
          await createSession(profile.childId);
        
          history.push(determinePostLoginScreen());
        } catch (e) {
          showSnackbar(e.message);
        }
      } else if (sessionHelper.childProfiles.length === 1) {
        // creating the session for the first child found in the sessionHelper
        const childId = sessionHelper.childProfiles[0].id;

        try {
          await createSession(childId);

          localStorage.setItem("kutProfile", JSON.stringify({
            id: sessionHelper.id,
            childId,
          }));
  
          history.push(determinePostLoginScreen());
        } catch (e) {
          showSnackbar(e.message);
        }
      } else {
        setDisplayPage(true);
      }
    }

    init();
  }, []);
  
  const customDomain = window?.kutuki?.domain ?? null;
  
  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img
            src={["app", "localhost"].includes(customDomain) ? logo : `https://cdn-schools.kutuki.in/logos/${customDomain}.png`}
            alt="Kutuki"
            className={classes.logo}
          />
        </Toolbar>
      </AppBar>
      <div style={{ maxWidth: 400, margin: "0 auto" }}>
        <ContentShell title="Select Profile" loading={!displayPage}>
          <List>
            {profiles.length === 0 && (
              <ListItem>
                <Typography>Profile(s) not found</Typography>
              </ListItem>
            )}
            {profiles.map((profile) => (
              <ListItem
                key={profile.id}
                className={classes.listItem}
                button
                onClick={handleProfileClick(profile.id, profile.parentId)}
              >
                <Avatar
                  src={profile.profilePicture}
                  alt={profile.name}
                  className={classes.avatar}
                />
                <Typography>{profile.name}</Typography>
              </ListItem>
            ))}
          </List>
          <Button onClick={handleSignOutClick}>
            Sign In with a different profile
          </Button>
        </ContentShell>
      </div>
    </>
  );
}

export default ProfileSelector;
