import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";

import { Client } from "services/_clientService";
import sessionHelper from "services/sessionHelper";

import { getDateSentForMessage } from "utils/miscUtils";

async function _getStaffProfiles() {

  const response = await new Client({
    path: `/user/contacts`,
  }).get();

  if (response.code !== 200)
    throw new Error("Sorry we are unable to load teacher profiles");

  return response?.data?.users?.map((curr) => ({
    avatar: curr.profilePicture,
    id: curr.id.toString(),
    name: curr.name,
  })) || [];
}

function _subscribeChats(callback) {
  const [field, value] =
    sessionHelper.role === "PARENT"
      ? ["studentId", sessionHelper?.selectedProfile?.id]
      : ["staffId", sessionHelper.id];

  if (!value) return;

  return firebase
    .firestore()
    .collection("chatsV2")
    .where(field, "==", value.toString())
    .orderBy("dateModified", "desc")
    .onSnapshot((snapshot) => {
      const chats = [];
      snapshot.forEach((doc) => chats.push(doc.data()));
      callback(chats);
    });
}

async function _createChat(staffId) {
  const studentId = sessionHelper?.selectedProfile?.id;

  const now = new Date().getTime();

  const chatId = `${staffId}_${studentId}`;

  const chat = {
    id: chatId,
    staffId: staffId.toString(),
    studentId: studentId.toString(),
    staffUnreadCount: 0,
    studentUnreadCount: 0,
    dateCreated: now,
    dateModified: now,
  };

  await firebase
    .firestore()
    .collection("chatsV2")
    .doc(chatId)
    .set(chat, { merge: true });

  return chat;
}

async function _updateChat(chat) {
  var chatRef = firebase.firestore().collection("chatsV2").doc(chat.id);

  console.log("Updating chat : ", chat);

  return await chatRef.update({ ...chat, dateModified: new Date().getTime() });
}

async function loadMessages(chat, callback) {
  console.log("Chat ID : ", chat.id);

  const unsubscribe = firebase
    .firestore()
    .collection(`chatsV2/${chat.id}/messages`)
    .onSnapshot((snapshot) => {
      const messages = [];

      snapshot.forEach((doc) => {
        const message = doc.data();
        message.dateSent = getDateSentForMessage(message.dateModified);
        messages.push(message);
      });

      callback(messages);
    });

  return unsubscribe;
}

async function saveMessage(chat, message) {
  console.log("Save Message : ", chat.id, message);

  const senderId =
    sessionHelper.role === "TEACHER" ? chat.staffId : chat.studentId;

  const messageId = `${new Date().getTime()}`;

  const now = new Date().toISOString();

  chat.staffUnreadCount += 1;

  await _updateChat({
    id: chat.id,
    staffUnreadCount: chat.staffUnreadCount,
  });

  await firebase
    .firestore()
    .collection(`chatsV2/${chat.id}/messages`)
    .doc(messageId)
    .set({
      id: messageId,
      senderId: senderId.toString(),
      message,
      unread: true,
      dateCreated: now,
      dateModified: now,
    });
}

async function readMessages(chat, messages) {
  let self = sessionHelper.role === "TEACHER" ? chat.staffId : chat.studentId;

  self = self.toString();

  const collectionRef = firebase
    .firestore()
    .collection(`chatsV2/${chat.id}/messages`);

  try {
    let message;
    for (let i = 0; i < messages.length; ++i) {
      message = messages[i];

      if (message.senderId !== self && message.unread) {
        const docRef = collectionRef.doc(message.id);

        await docRef.update({ unread: false });
      }
    }

    if (chat.studentUnreadCount)
      await _updateChat({
        id: chat.id,
        studentUnreadCount: 0,
      });
  } catch (err) {
    console.log("Error in updating chat : ", chat.id);
    console.log(err);
  }
}

async function getConversations(callback) {
  const profiles = await _getStaffProfiles();

  return _subscribeChats(async (chats) => {
    const activeChats = [];
    for (let profile of profiles) {
      let chat = chats.find((chat) => chat.staffId === profile.id);

      if (!chat) {
        chat = await _createChat(profile.id);
        chats.push(chat);
      }

      chat.recipient = profile.name;
      chat.avatar = profile.avatar;

      activeChats.push(chat);
    }

    callback(activeChats);
  });
}

async function getUnreadConversations() {
  try {
    const [field, value] =
    sessionHelper.role === "PARENT"
      ? ["studentId", sessionHelper?.selectedProfile?.id]
      : ["staffId", sessionHelper.id];

    const snapshot = await firebase
      .firestore()
      .collection("chatsV2")
      .where(field, "==", value?.toString())
      .where("studentUnreadCount", ">", 0)
      .get();

    let count = 0;

    snapshot.forEach((c) => (count += 1));

    return count;
  } catch (e) {
    return 0;
  }
}

export {
  loadMessages,
  saveMessage,
  readMessages,
  getConversations,
  getUnreadConversations,
};
