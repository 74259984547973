import sessionHelper from "services/sessionHelper";
import moment from "moment";

export function calculatePreschoolExpiry(metadata, startDate) {
  if (typeof metadata === 'string') metadata = JSON.parse(metadata);

  let date = new Date(startDate);
  let duration = 0;

  if (metadata?.durationValue)
    duration = duration + metadata.durationValue;

  date.setDate(date.getDate() + duration);

  let result = new Date(date);

  return result;
}

export const calculateExpiry = (expireDate) => {
  if (!expireDate)
    return false;
  let today = new Date();
  let endDate = new Date(expireDate);

  return today > endDate;
}

export const canAccessAssignment = (courseId) => {
  const batches = sessionHelper.paymentOptions;

  if (batches && Object.keys(batches)?.length) {
    for (const map of Object.keys(batches)) {
      let data = batches[map];
      if (data[0]?.courseId === courseId)
        return { canView: false, batchId: +map };
    }
  }

  return { canView: true, batchId: -99 };
}

export const isOneWeekDifference = (userCourseMapping) => {
  if (!userCourseMapping) {
    const productMap = sessionHelper?.productMap;
    userCourseMapping = sessionHelper?.userCourseMappings.find(mapping =>
      Boolean(mapping?.course?.plans?.find(planId => productMap[planId]?.type === 'preschool'))
    );
  }

  if (!userCourseMapping)
    return { diplayPaymentOption: false, count: 0, batchId: -99 }

  const expiryDate = moment(userCourseMapping.expiryDate).format("YYYY-MM-DD");

  const currentDate = moment().format("YYYY-MM-DD");

  const days = moment(expiryDate).diff(currentDate, 'days');

  if (days <= 7)
    return { diplayPaymentOption: true, count: 1, batchId: userCourseMapping?.batchId };

  return { diplayPaymentOption: false, count: 0, batchId: -99 }
}