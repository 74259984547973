import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const ConfirmationDialogContext = React.createContext({});

const ConfirmationDialog = () => {
  const classes = useStyles();

  const {
    _dialogOpen,
    _title,
    _message,
    _actionButtonLabel="Confirm",
    _onConfirm,
    _onDismiss,
  } = React.useContext(ConfirmationDialogContext);

  return (
    <Dialog
      open={_dialogOpen}
      onClose={_onDismiss}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <DialogTitle>{_title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{_message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onDismiss}>Cancel</Button>
        <Button color="primary" variant="contained" onClick={_onConfirm}>
          {_actionButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ConfirmationDialogProvider = ({ children }) => {
  const [_dialogOpen, _setDialogOpen] = React.useState(false);
  const [_confirmConfig, _setConfirmConfig] = React.useState({});

  const _openDialog = ({ actionCallback, title, message, actionButtonLabel }) => {
    _setDialogOpen(true);
    _setConfirmConfig({
      actionCallback,
      title,
      message,
      actionButtonLabel
    });
  };

  const _resetDialog = () => {
    _setDialogOpen(false);
    _setConfirmConfig({});
  };

  const _onConfirm = () => {
    _resetDialog();
    _confirmConfig.actionCallback(true);
  };

  const _onDismiss = () => {
    _resetDialog();
    _confirmConfig.actionCallback(false);
  };

  return (
    <ConfirmationDialogContext.Provider
      value={{
        _dialogOpen,
        _title: _confirmConfig.title,
        _message: _confirmConfig.message,
        _actionButtonLabel: _confirmConfig.actionButtonLabel,
        _openDialog,
        _onConfirm,
        _onDismiss,
      }}
    >
      <ConfirmationDialog />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

const useConfirmationDialog = () => {
  const { _openDialog } = React.useContext(ConfirmationDialogContext);

  const getConfirmation = ({ ...options }) =>
    new Promise((res) => {
      _openDialog({ actionCallback: res, ...options });
    });

  return { getConfirmation };
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  dialogTitle: PropTypes.string,
  dialogContent: PropTypes.string,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
};

export default ConfirmationDialog;
export { ConfirmationDialogProvider, useConfirmationDialog };
