import React from "react";
import {withStyles} from "@material-ui/core/styles";

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: 0,
      images: props.images,
    };

    ['nextSlide', 'prevSlide'].forEach(methodName => {
      this[methodName] = this[methodName].bind(this);
    });
    
    this.intervalId = 0;
  }

  prevSlide() {
    const { currentImageIndex, images } = this.state;
    const index = currentImageIndex === 0
      ? images.length - 1
      : currentImageIndex - 1;

    this.setState({ currentImageIndex: index });
  }

  nextSlide() {
    const { currentImageIndex, images } = this.state;
    const index = currentImageIndex === (images.length - 1)
      ? 0
      : currentImageIndex + 1;

    this.setState({ currentImageIndex: index });
  }

  componentDidMount() {
    if (this.props.autoplay)
      this.intervalId = setInterval(
        () => this.nextSlide(),
        this.props.duration || 2000
      );
  }
  
  componentWillUnmount() {
    this.intervalId && clearInterval(this.intervalId);
  }
  
  render() {
    const { currentImageIndex: index, images } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.carouselContainer}>
        <img
          src={images[index]}
          alt={`login-carousel-images-${index}`}
          className={classes.carouselImage}
        />
      </div>
    );
  }
}

export default withStyles({
  carouselArrow: {
    fontWeight: "bold",
    fontSize: 40,
    cursor: "pointer",
  },
  carouselContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    minWidth: 320,
    height: "60vh",
  },
  carouselImage: {
    height: "100%",
    overflow: "auto",
  }
})(Carousel);
