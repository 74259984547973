import React from "react";
import dayjs from "dayjs";
import "dayjs/plugin/customParseFormat";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import useSnackbar from "hooks/useSnackbar";
import BatchExpiryStatus from "components/dialogs/BatchExpiryStatus";
import AppShell from "components/AppShell";
import ContentShell from "components/ContentShell";
import { SimpleDateInput } from "components/FormDateInput";
import LoadingIndicator from "components/LoadingIndicator";
import { getDailySchedule } from "services/coursesService";
import sessionHelper from "services/sessionHelper";
import liveClassIcon from "assets/live-class.png";
import { isOneWeekDifference } from "utils/preschoolHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  loadingIndicator: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  scheduleContainer: {
    padding: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    position: "relative",
    width: "100%",
    maxWidth: 800,
    minHeight: 400,
    backgroundColor: "#54bfcf80",
  },
  classList: {
    marginTop: 30,
  },
  classTypeIcon: {
    height: 40,
    width: 60,
  },
}));

function Actions({ date, onDateChange }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <SimpleDateInput fullWidth={false} value={date} onChange={onDateChange} />
    </div>
  );
}

function MobileView({
  handleScheduleItemClick,
  handleStartMeeting,
  item,
  loading,
}) {
  return (
    <Box
      onClick={handleScheduleItemClick}
      display="flex"
      flexWrap="wrap"
      style={{ marginBottom: 24 }}
    >
      <img src={liveClassIcon} alt="LIVE" height="16px" style={{ margin: 8 }} />
      <Typography variant="body2" gutterBottom={false} style={{ margin: 8 }}>
        {item.courseTypeName} {item.courseName} {` - Session ${item.session}`}
      </Typography>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <Typography
          variant="body2"
          gutterBottom={false}
          style={{ margin: 8, flexGrow: 1 }}
        >
          <strong>{item?.startTime}</strong>
          &nbsp;&nbsp;{"-"}&nbsp;&nbsp;
          <strong>{item?.endTime}</strong>
        </Typography>
        {item.joinMeeting && (
          <Button
            disabled={loading}
            variant="outlined"
            color="secondary"
            style={{padding: 4, fontSize: 8, margin: 4, alignSelf: "end"}}
            onClick={() => handleStartMeeting(item)}
          >
            JOIN
          </Button>
        )}
      </Box>
    </Box>
  );
}

function DailySchedule() {
  const classes = useStyles();

  const showSnackbar = useSnackbar();

  const initialDate = React.useMemo(() => dayjs().format("DD MMM, YYYY"), []);

  const [date, setDate] = React.useState(initialDate);

  const [loading, setLoading] = React.useState(false);

  const [schedule, setSchedule] = React.useState([]);

  const [open, setOpen] = React.useState(false);

  const [preschoolBatchId, setPreschoolBatchId] = React.useState(-99)

  const handleClose = () => setOpen(false);

  const childId = React.useMemo(
    () => sessionHelper?.selectedProfile?.id,
    [sessionHelper.selectedProfile]
  );

  const handleScheduleItemClick = (id) => {};

  const handleDateChange = (date) => {
    setDate(dayjs(date).format("DD MMM, YYYY"));
  };

  const handleStartMeeting = async (sessionObj) => {
    if (sessionObj?.courseTypeName?.toUpperCase() === "PRESCHOOL" && sessionHelper?.paymentOptions[sessionObj.batchId]) {
        setOpen(true);
    } else {
      const {
        session: sessionNumber,
        batchId,
        courseId,
        metadata,
        meetingLink,
      } = sessionObj;
  
      if (metadata && metadata.useMeeting) {
        console.log("Using Zoom Meeting Link : ", meetingLink);
        window.open(meetingLink, "_blank");
      } else {
        window.location.href = `${process.env.REACT_APP_MEETING_UI}/?cid=${courseId}&bid=${batchId}&session=${sessionNumber}&sid=${childId}`;
      }
    }
  };

  React.useEffect(() => {
    setLoading(true);

    getDailySchedule({ date, childId })
      .then((_schedule) => {
        setSchedule(_schedule);
        setLoading(false);
      })
      .catch((e) => {
        showSnackbar(e.message);
        setLoading(false);
      });
  }, [date, childId]);

  React.useEffect(() => {
    if (!localStorage.getItem("kutDisplayPaymentOption")) {
      const data = isOneWeekDifference();

      if (data?.diplayPaymentOption && data?.batchId !== -99) {
        localStorage.setItem("kutDisplayPaymentOption", JSON.stringify(data));
        setOpen(true);
        setPreschoolBatchId(data?.batchId);
      }
    }
  }, []);

  return (
    <AppShell>
      <ContentShell
        loading={false}
        title="Daily Schedule"
        actions={<Actions date={date} onDateChange={handleDateChange} />}
      >
        <div className={classes.root}>
          <Paper elevation={2} className={classes.scheduleContainer}>
            <div className={classes.classList}>
              {loading && (
                <div className={classes.loadingIndicator}>
                  <LoadingIndicator />
                </div>
              )}
              {!loading &&
                schedule.map((item, index) => (
                  <div key={index}>
                    <Hidden xsDown>
                      <Grid
                        container
                        justify="center"
                        key={index}
                        onClick={handleScheduleItemClick}
                        style={{ marginBottom: 20 }}
                      >
                        <Grid item sm={2}>
                          <img
                            src={liveClassIcon}
                            alt="LIVE"
                            className={classes.classTypeIcon}
                          />
                        </Grid>
                        <Grid item sm={4}>
                          <Typography variant="h6" gutterBottom={false}>
                            {item?.metadata?.title
                              ? `${item.courseTypeName} ${item.courseName} - ${item.metadata.title}`
                              : `${item.courseTypeName} ${item.courseName} - Session ${item.session}`
                            }
                          </Typography>
                        </Grid>
                        <Grid item sm={4} align="center">
                          <Typography variant="h6" gutterBottom={false}>
                            <strong>{item?.startTime}</strong>
                            &nbsp;&nbsp;{"-"}&nbsp;&nbsp;
                            <strong>{item?.endTime}</strong>
                          </Typography>
                        </Grid>
                        <Grid item sm={2} align="left">
                          {item.joinMeeting && sessionHelper?.permissions?.daily_schedule?.can_join && (
                            <Button
                              disabled={loading}
                              className={classes.submitButton}
                              variant="outlined"
                              color="secondary"
                              onClick={() => handleStartMeeting(item)}
                            >
                              JOIN
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Hidden>
                    <Hidden smUp>
                      <Box display="flex" flexDirection="column">
                        <MobileView
                          handleScheduleItemClick={handleScheduleItemClick}
                          handleStartMeeting={handleStartMeeting}
                          item={item}
                          loading={loading}
                        />
                      </Box>
                    </Hidden>
                    <BatchExpiryStatus
                      open={open}
                      handleClose={handleClose}
                      batchId={item?.batchId || preschoolBatchId}
                      isExpired={sessionHelper?.paymentOptions[item.batchId] ? true : false}
                    />
                  </div>
                ))}
              {schedule.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5">
                    Nothing planned for the day
                  </Typography>
                </div>
              )}
            </div>
          </Paper>
        </div>
      </ContentShell>
    </AppShell>
  );
}

export default DailySchedule;
