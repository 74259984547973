import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	subTitle: {
		color: "#b3b3b3",
		fontWeight: 600,
	},
	detail: {
		fontWeight: 600,
	},
	childContainer: {
		maxHeight: 100,
		overflow: "scroll",
		display: "flex",
		flexDirection: "column",
		marginTop: 16,
	},
	rowContainer: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},
}));

export default function CalendarEventDialog({ handleClose, open, title, titleV2, materialsRequired }) {
	const classes = useStyles();
	
	return (
		<div>
			<Dialog open={open} fullWidth>
				<DialogTitle>
					<Box className={classes.rowContainer}>
						<span style={{ fontWeight: 800, fontSize: "1.2rem" }}>
							{title}
						</span>
						{titleV2 !== null && (
							<span style={{ fontWeight: 800, fontSize: "1.2rem" }}>
								({titleV2})
            	</span>
						)}
					</Box>
					<Tooltip title={<Typography>Close</Typography>}>
						<IconButton onClick={handleClose} className={classes.closeButton}>
							<CloseIcon />
						</IconButton>
					</Tooltip>
				</DialogTitle>
				<DialogContent dividers>
					<Typography gutterBottom><strong>Materials Required</strong></Typography>
					<List>
						{materialsRequired && <ListItem><Typography>{materialsRequired}</Typography></ListItem>}
						<ListItem><Typography>Atta/Playdough</Typography></ListItem>
						<ListItem><Typography>Paper</Typography></ListItem>
						<ListItem><Typography>Pencil</Typography></ListItem>
						<ListItem><Typography>Paint</Typography></ListItem>
						<ListItem><Typography>Paintbrush</Typography></ListItem>
						<ListItem><Typography>Water</Typography></ListItem>
						<ListItem><Typography>Crayons/ sketch pens</Typography></ListItem>
						<ListItem><Typography>Scissors</Typography></ListItem>
						<ListItem><Typography>Waste cloth</Typography></ListItem>
						<ListItem><Typography>Glue</Typography></ListItem>
					</List>
				</DialogContent>
			</Dialog>
		</div>
	);
}
