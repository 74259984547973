import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import ContentShell from "components/ContentShell";
import Gallery from "components/Gallery";
import useList from "hooks/useList";
import { getThemes } from "services/contentService";
import { SimpleSelectInput } from "components/FormSelectInput";
import BatchExpiryStatus from "components/dialogs/BatchExpiryStatus";
import sessionHelper from "services/sessionHelper";
import { logEvent } from "services/analyticsService";
import { getFilterByScreen } from "utils/filterUtils";
import { calculateExpiry } from "utils/preschoolHelper";

const screenName = "learning-material";

const useStyles = makeStyles((theme) => ({
  selectMenu: {
    minWidth: 300,
    [theme.breakpoints.down("xs")]: {
      minWidth: 150,
    },
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer"
  }
}));

function Themes() {
  const history = useHistory();

  const classes = useStyles();

  const [open, setOpen] = React.useState(true);

  const [courses, setCourses] = React.useState([]);

  const handleClose = () => setOpen(false);

  const {
    loading,
    list,
    total,
    page,
    pageSize,
    conditions,
    changePage,
    setFilter,
  } = useList({ getList: getThemes, screenName });

  const handleClick = (themeId, item) => {
    logEvent("LEARNING_MATERIAL", {
      themeId: themeId,
      theme: item,
      courseId: conditions.courseId,
      userId: sessionHelper.isLoggedIn() ? sessionHelper.id : "NA",
    });

    let url = `/learning-materials/${themeId}/content`;
    if (conditions.courseId && conditions?.courseId !== -99)
      url += `?courseId=${conditions.courseId}`;
    if (conditions.subCourseId && conditions?.subCourseId !== -99)
      url += `&subCourseId=${conditions.subCourseId}`;

    history.push(url);
  };

  const _getBatchInformation = (course) => {
    const batch = sessionHelper?.batches?.find((data) => data.courseId === course.id);

    return {
      isExpired: course?.courseType === "ONLINE_PRESCHOOL"
        ? calculateExpiry(batch.expiryDate)
        : false,
      batchId: batch?.id
    }
  }

  React.useEffect(() => {
    const _courses = sessionHelper.courses || [];

    let filters = getFilterByScreen(screenName);

    const filteredCourses = _courses.filter(
      (data) => data.courseType !== "DEMO" && data.courseType !== "DIGITAL_WORKSHEET"
    );

    const courseOptions = filteredCourses.map((course) => ({
      id: course.id,
      label: `${course.courseTypeName} - ${course.name}`,
      subCourses:
        course?.courseModule?.map((subCourse) => ({
          id: subCourse.code,
          label: subCourse.name,
        })) || [],
      batchDetails: _getBatchInformation(course)
    }));

    if (!filters?.courseId) {
      setFilter(screenName, "courseId", courseOptions?.length > 0 ? courseOptions[0].id : -99);
    }

    setCourses(courseOptions);
  }, []);

  const modules = React.useMemo(() => {
    let filters = getFilterByScreen(screenName);

    const course = courses?.filter(_course => _course.id === conditions.courseId);
    
    const _modules = (course?.[0]?.subCourses) ? course[0].subCourses : [];
    
    if (typeof filters?.subCourseId !== "undefined" && filters?.subCourseId !== null && filters?.subCourseId !== -99) {
      const selectedModule = !_modules.length ? -99 : _modules.find((map) => map.id === filters?.subCourseId);

      setFilter(screenName, "subCourseId", selectedModule?.id || -99);
    } else {
      setFilter(screenName, "subCourseId", !_modules.length ? -99 : _modules[0].id);
    }
    
    return _modules;
  }, [conditions.courseId]);
  
  return (
    <ContentShell
      loading={loading}
      title="Learning Materials"
      actions={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <SimpleSelectInput
            className={classes.selectMenu}
            fullWidth={true}
            name="courseId"
            label="Courses"
            value={conditions.courseId}
            options={courses}
            onChange={(event) => setFilter(screenName, "courseId", event.target.value)}
          />
          {modules.length > 0 && <SimpleSelectInput
            className={classes.selectMenu}
            style={{ marginLeft: 5 }}
            fullWidth={true}
            name="subCourseId"
            label="Module"
            value={conditions.subCourseId || -99}
            options={modules}
            onChange={(event) => setFilter(screenName, "subCourseId", event.target.value)}
          />}
        </div>
      }
    >
      {!courses.find((course) => course.id === conditions.courseId)?.batchDetails?.isExpired ? (
        <Gallery
          list={list}
          currentPage={page}
          pageSize={pageSize}
          totalEntries={total}
          onChangePage={changePage}
          onThumbnailClick={handleClick}
        />
      ) : (
        <>
        <Typography>
          Please <span className={classes.link} onClick={() => setOpen(true)}>renew</span> the course to access the learning materials and worksheets.
        </Typography>
        <BatchExpiryStatus
          open={open}
          handleClose={handleClose}
          batchId={courses?.find((course) => course.id === conditions.courseId)?.batchDetails?.batchId}
        />
      </>
      )}
    </ContentShell>
  );
}

export default Themes;
