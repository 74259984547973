import draftToHTML from "draftjs-to-html";
import { Client, FileUpload } from "./_clientService";
import sessionHelper from "./sessionHelper";
import { logEvent } from "./analyticsService";
/**
 * Get the assignment details for the provided
 * assignment id
 * @param id
 * @returns {Promise<*>}
 */
async function getAssignmentById(id) {
  const result = await new Client({
    path: "/assignments/" + id,
  }).get();

  if (result.code !== 200) {
    throw new Error(result.message);
  }

  const assignment = result.data;

  let description = "";

  try {
    description = JSON.parse(assignment.description);
    description = draftToHTML(description);
  } catch (e) {
    console.log(e);
    description = assignment.description;
  }

  if (!description) description = "Description not available";

  return {
    ...assignment,
    description
  };
}

/**
 * Get a list of the assignments for the current
 * logged in user.
 * @returns {Promise<any>}
 */
async function getAssignments({ page, ...conditions }) {
  let finalPath = `/assignments?pageNo=${page}&includeCourse=true&includeCourseType=true&includeTotal=true`;

  if (conditions.courseId && conditions.courseId !== -99) {
    finalPath += `&courseId=${conditions.courseId}`;
  } else {
    const courseIds = sessionHelper.courses.map(course => course.id).join(",");
    finalPath += `&courseId=${courseIds.length ? courseIds : -99}`;
  }

  finalPath += `&order=asc&by=session`

  const result = await new Client({
    path: finalPath,
  }).get();

  if (result.code !== 200) {
    throw new Error(result.message);
  }

  return {
    data: result?.data?.assignments.map(assignment=> ({
        ...assignment,
        courseName: assignment.course.name,
        courseTypeName: assignment.courseType.name,
    })),
    totalEntries: result?.data?.total,
    pageSize: result.data.pageSize,
  };
}

/**
 * Get the files submitted for the given assignment id.
 * @param id
 * @returns {Promise<any>}
 */
async function getSubmissionsByAssignment(id) {
  const result = await new Client({
    path: `/assignments/${id}/submissions?childId=${sessionHelper?.selectedProfile?.id}`,
  }).get();

  if (result.code !== 200) {
    throw new Error(result.message);
  }

  return result?.data?.submissions.map((submission) => ({
    ...submission,
    type: submission?.fileType?.toUpperCase(),
  }));;
}

/**
 * Submit a new files for the provided assignment id.
 * @param values
 * @param id
 * @returns {Promise<any>}
 */
async function saveAssignmentSubmission(values, id) {
  const uploadOps = [];

  values.files.forEach((file) => {
    const extension = file.name.slice(file.name.lastIndexOf("."));
    const childName = sessionHelper?.selectedProfile?.name || "";

    const fileName = `${childName.replace(
      /[,\s]/g,
      "_"
    )}-${new Date().getTime()}${extension}`;

    uploadOps.push(
      new FileUpload().upload({
        file,
        fileName,
        directory: `app/submissions`,
      })
    );
  });

  const uploadOpsResult = await Promise.all(uploadOps);

  const files = uploadOpsResult.map(
    (curr) => `https://cdn-schools.kutuki.in/${curr.Key}`
  );

  const result = await new Client({
    path: `/assignments/${id}/submissions`,
    payload: {
      files,
      userId: sessionHelper.id,
      childId: sessionHelper?.selectedProfile?.id,
      assignmentId:id,
    },
  }).post();

  if (result.code !== 200) {
    throw new Error(result.message);
  }

  logEvent("ASSIGNMENT_SUBMISSION", {
    childName: sessionHelper?.selectedProfile?.name,
    userId: sessionHelper.isLoggedIn() ? sessionHelper.id : "NA",
  });

  return result;
}

export {
  getAssignmentById,
  getAssignments,
  getSubmissionsByAssignment,
  saveAssignmentSubmission,
};
