import React from "react";

function NotFound(){  
  React.useEffect(()=>{
    window.location = "https://kutuki.in/404";
  });

  return "";
}

export default NotFound;
