import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/Paper";
import MuiTable from "@material-ui/core/Table";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ContentShell from "components/ContentShell";
import Pagination from "components/Pagination";

const useStyles = makeStyles((theme) => ({
  tableHead: {
    fontWeight: "bold",
  },
  card: {
    width: "100%",
    minHeight: 220,
    border: "1px solid",
    borderColor: "#e6e6e6",
    borderRadius: 8,
    margin: 5,
    padding: 15,
  },
  title: {
    fontWeight: 800,
    fontSize: "1.8em",
    width: "30%",
    marginRight: 10,
    marginBottom: 16,
  },
  data: {
    width: "70%",
    fontSize: "1.8em",
    marginBottom: 16,
  },
}));

const Head = ({ columns }) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.numeric ? "right" : "left"}
            className={classes.tableHead}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const Row = ({ actions: Actions, columns, data, rowId, onRowClick }) => {
  return (
    <TableRow
      hover
      onClick={() => onRowClick(data.id)}
      style={{ cursor: "pointer" }}
    >
      <>
        {columns.map((column) =>
          column.id === "actions" ? (
            <TableCell key={column.id} align="right" padding="checkbox">
              <Actions rowId={rowId} data={data} />
            </TableCell>
          ) : (
            <TableCell
              align={column.numeric ? "right" : "left"}
              key={column.id}
            >
              {data[column.id]}
            </TableCell>
          )
        )}
      </>
    </TableRow>
  );
};

Row.defaultProps = {
  onRowClick: () => {},
  actions: () => <></>,
};

const Body = ({ actions, rows, columns, onRowClick }) => {
  return (
    <TableBody>
      {rows.map((row, index) => (
        <Row
          key={row.id || index}
          actions={actions}
          columns={columns}
          data={row}
          rowId={row.id}
          onRowClick={onRowClick}
        />
      ))}
    </TableBody>
  );
};

const Card = ({ actions: Actions, rows, columns, onRowClick }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center" width="100%">
      {rows.map((row, index) => (
        <Box className={classes.card}>
          {columns.map((column) => (
            <Box display="flex" justifyContent="left">
              <Box className={classes.title}>
                {column.id === "actions" ? null : `${column.label}:`}
              </Box>
              <Box className={classes.data}>{row[column.id]}</Box>
              {column.id === "actions" && <Actions rowId={row.id} data={row} />}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

function Table(props) {
  const {
    columns,
    actions,
    data,
    onRowClick,
    currentPage,
    pageSize,
    totalEntries,
    onChangePage,
    removeShadow = false,
  } = props;

  return (
    <>
      <Hidden xsDown>
        <TableContainer
          style={{
            boxShadow: removeShadow && "none",
            width: "100%",
            overflow: "auto",
          }}
        >
          <MuiTable>
            <Head columns={props.columns} />
            <Body
              actions={actions}
              rows={data}
              columns={columns}
              onRowClick={onRowClick}
            />
          </MuiTable>
          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            totalEntries={totalEntries}
            onChangePage={onChangePage}
          />
        </TableContainer>
      </Hidden>
      <Hidden smUp>
        <Card
          actions={actions}
          rows={data}
          columns={columns}
          onRowClick={onRowClick}
        />
        <Pagination
          currentPage={currentPage}
          pageSize={pageSize}
          totalEntries={totalEntries}
          onChangePage={onChangePage}
        />
      </Hidden>
    </>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      numeric: PropTypes.bool,
    })
  ),
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  data: PropTypes.array,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  totalEntries: PropTypes.number,
  onRowClick: PropTypes.func,
  onChangePage: PropTypes.func,
};

export default Table;
export { ContentShell as TableShell };
