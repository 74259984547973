import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import ContentShell from "components/ContentShell";
import Gallery, { MobileThemeThumbnail } from "components/Gallery";
import useList from "hooks/useList";
import { getThemesFirestore } from "services/contentService";
import sessionHelper from "services/sessionHelper";
import { logEvent } from "services/analyticsService";

const screenName = "rhymes-and-stories";

const useStyles = makeStyles((theme) => ({
  selectMenu: {
    minWidth: 300,
    [theme.breakpoints.down("xs")]: {
      minWidth: 150,
    },
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer"
  }
}));

function ThemesMobile() {
  const history = useHistory();

  const {
    loading,
    list,
    total,
    page,
    pageSize,
    changePage,
  } = useList({ getList: getThemesFirestore, screenName });

  const handleClick = (themeId, item) => {
    logEvent("RHYMES_AND_STORIES", {
      themeId: themeId,
      theme: item,
      userId: sessionHelper.isLoggedIn() ? sessionHelper.id : "NA",
    });

    let url = `/rhymes-and-stories/${themeId}/content`;

    history.push(url);
  };
  
  return (
    <ContentShell
      loading={loading}
      title="Rhymes & Stories"
      actions={[]}
    >
      <Gallery
        list={list}
        currentPage={page}
        pageSize={pageSize}
        totalEntries={total}
        thumbnailComponent={MobileThemeThumbnail}
        onChangePage={changePage}
        onThumbnailClick={handleClick}
      />
    </ContentShell>
  );
}

export default ThemesMobile;
