import React from "react";

/**
 * Hook to add callback to the "beforeunload"
 * event listener
 *
 * @param handler
 */
const useBeforeUnload = (handler = () => {}) => {
  if (typeof handler !== "function") {
    throw new TypeError(
      `Expected "handler" to be a function, not ${typeof handler}.`
    );
  }

  const handlerRef = React.useRef(handler);

  React.useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  React.useEffect(() => {
    const handleBeforeunload = (event) => {
      let returnValue;

      if (typeof handlerRef.current === "function") {
        returnValue = handlerRef.current(event);
      }

      if (event.defaultPrevented) {
        event.returnValue = "";
      }

      if (typeof returnValue === "string") {
        event.returnValue = returnValue;
        return returnValue;
      }
    };

    window.addEventListener("beforeunload", handleBeforeunload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, []);
};

export default useBeforeUnload;
