import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import FilterIcon from "@material-ui/icons/FilterList";
import { SimpleSelectInput } from "components/FormSelectInput";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AppShell from "components/AppShell";
import BatchExpiryStatus from "components/dialogs/BatchExpiryStatus";
import LoadingIndicator from "components/LoadingIndicator";
import ContentShell from "components/ContentShell";
import SimpleAccordion from "components/SimpleAccordion";
import ContentPreview from "components/ContentPreview";
import FilterDialog from "components/dialogs/FilterDialog";
import sessionHelper from "services/sessionHelper";
import { getCourseTypes } from "services/coursesService";
import { getRecordings } from "services/meetingsService";
import { formatDate, recordingDateFormat } from "utils/miscUtils";

const useStyles = makeStyles((theme) => ({
  tableHead: {
    fontWeight: "bold",
  },
  data: {
    textAlign: "left",
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer"
  }
}));

const Filters = ({ courseTypes, courseOptions, handleChange }) => {
  const [courseTypeValue, setCourseTypeValue] = React.useState(-99);

  const [courseValue, setCourseValue] = React.useState("");
  return (
    <Box display="flex" justifyContent="space-evenly" flexWrap="wrap">
      <Box width="145px" m={1}>
        <SimpleSelectInput
          name="courseType"
          label="Course Type"
          options={courseTypes || []}
          value={courseTypeValue}
          onChange={(e) => {
            setCourseTypeValue(e.target.value);
            if (e.target.value === -99) setCourseValue("");
            handleChange(e.target.value, "courseType");
          }}
        />
      </Box>
      <Box width="145px" m={1}>
        <SimpleSelectInput
          name="courseName"
          label="Course Name"
          disabled={courseTypeValue === -99}
          options={courseOptions || []}
          value={courseValue}
          onChange={(e) => {
            setCourseValue(e.target.value);
            handleChange(e.target.value, "course");
          }}
        />
      </Box>
    </Box>
  );
};

const ScreenActions = ({
  handleFilterClick,
  courseTypes,
  courseOptions,
  handleChange,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Hidden smDown>
        <Filters
          courseTypes={courseTypes}
          courseOptions={courseOptions}
          handleChange={handleChange}
        />
      </Hidden>
      <Hidden mdUp>
        <IconButton onClick={handleFilterClick}>
          <FilterIcon />
        </IconButton>
      </Hidden>
    </div>
  );
};

const SessionTable = ({ meetings, handleViewClick }) => {
  const classes = useStyles();

  const [classId, setClassId] = React.useState([]);

  React.useEffect(() => {
    let objectProperty = Object.keys(meetings);
    setClassId(objectProperty);
  }, []);

  return (
    <>
      {Object.keys(meetings).length > 0 ? (
        <TableContainer style={{ maxHeight: 400 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>Date</TableCell>
                <TableCell className={classes.tableHead}>
                  Session Number
                </TableCell>
                <TableCell className={classes.tableHead}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {classId?.map((id, index) => (
                <TableRow>
                  <TableCell className={classes.data}>
                    {recordingDateFormat(meetings[id][0].dateCreated)}
                  </TableCell>
                  <TableCell className={classes.data}>
                    Session - {id}
                  </TableCell>
                  <TableCell className={classes.data}>
                    <Button
                      color="primary"
                      size="medium"
                      onClick={() => handleViewClick(meetings[id])}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography color="secondary">No Recordings available</Typography>
      )}
    </>
  );
};
const ClassRecordings = () => {
  const [allBatches, setAllBatches] = React.useState([]);

  const [batches, setBatches] = React.useState([]);

  const [open, setOpen] = React.useState(false);

  const [courseTypes, setCourseTypes] = React.useState([]);

  const [courseOptions, setCourseOptions] = React.useState([]);

  const [filteredCourseOptions, setFilteredCourseOptions] = React.useState([]);

  const [loading, setLoading] = React.useState(true);

  const [loadingTable, setLoadingTable] = React.useState(false);

  const [openContentViewer, setOpenContentViewer] = React.useState(false);

  const [selectedFile, setSelectedFile] = React.useState();

  const [batch, setBatch] = React.useState({});

  const [openPaymentDialog, setOpenPaymentDialog] = React.useState({open: false, batchId: -99})

  const handleViewClick = (meeting) => {
    const files = [];
    let count = 1;
    for (let data of meeting) {
      if (data.recordingUrl)
        files.push({
          file: data.recordingUrl,
          id: data.batchId,
          name: `Recording-${count++}`,
          thumbnailURL: "",
          type: "VIDEO",
        });
    }

    setSelectedFile(files);

    setOpenContentViewer(true);
  };

  const handleChange = (value, type) => {
    let filterBatch;

    switch (type) {
      case "courseType":
        if (value === -99) {
          setBatches(allBatches);
          setFilteredCourseOptions([]);
          return;
        }
        
        let courses = courseOptions.filter(
          (data) => data.courseTypeId === value
        );
        filterBatch = allBatches.filter((data) => data.courseTypeId === value);

        let uniqueCourse = [];
        let ids = [];
        for (let data of courses) {
          if (!ids.includes(data.id)) {
            uniqueCourse.push(data);
            ids.push(data.id);
          }
        }

        setBatches(filterBatch);
        setFilteredCourseOptions(uniqueCourse);
        break;
      case "course":
        filterBatch = allBatches.filter(
          (data) =>
            data.courseId === value &&
            batches[0].courseTypeId === data.courseTypeId
        );
        setBatches(filterBatch);
        break;
      default:
        console.log("err");
    }
  };

  const handleReset = () => {
    setBatches(allBatches);
    setOpen(false);
  };

  const handleClose = () => setOpenContentViewer(false);

  function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const fetchRecordings = async (batch) => {
      const result = await getRecordings(batch);
      let data = result?.length > 0 ? groupBy(result, "classId") : [];
      return data;
  };

  const handleAccordionState = async (e, state, batch) => {
    if (sessionHelper?.paymentOptions[batch?.id?.toString()]) {
      setOpenPaymentDialog({ open: true, batchId: batch.id });
    } else {
      setBatch(state ? batch : {});

      if (!batch.checked && state) {
        setLoadingTable(true);

        let finalBatch = await fetchRecordings({
          batchId: batch.id,
          courseId: batch.courseId,
        });
        batch.meetings = finalBatch;
        batch.checked = true;
        setLoadingTable(false);
      }
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      const result = await getCourseTypes();

      let data = result.filter((courseType) => courseType.id !== 4);

      let formatedResult = data.map((data) => {
        return { id: data.id, label: data.name };
      });

      let distincCourseTypeIds = [
        ...new Set(sessionHelper?.batches.map((item) => item.courseTypeId)),
      ];

      let finalResult = [];

      for (let item of formatedResult) {
        if (distincCourseTypeIds.includes(item.id)) finalResult.push(item);
      }

      setCourseTypes([{ id: -99, label: "All courses" }, ...finalResult]);
      setLoading(false);
    }

    let completedBatches = [];
    let courses = [];

    for (let batch of sessionHelper?.batches)
      if (batch.status !== "UPCOMING") {
        courses.push({
          id: batch.courseId,
          label: batch.courseName,
          batchId: batch.id,
          courseTypeId: batch.courseTypeId,
          expiryDate: batch.expiryDate
        });

        batch.meetings = {};

        batch.checked = false;

        completedBatches.push(batch);
      }

    completedBatches.sort(function (a, b) {
      return new Date(b.startDate) - new Date(a.startDate);
    });

    fetchData();

    setCourseOptions(courses);

    setAllBatches(completedBatches);

    setBatches(completedBatches);
  }, []);

  return (
    <AppShell>
      <ContentShell
        title="Recordings"
        loading={loading}
        actions={
          <ScreenActions
            handleFilterClick={() => setOpen(true)}
            courseTypes={courseTypes}
            courseOptions={filteredCourseOptions}
            handleChange={handleChange}
          />
        }
      >
        {batches?.map((_batch) => (
          <SimpleAccordion
            title={`${_batch.courseTypeName} - ${
              _batch.courseName
            } - (${formatDate(_batch.startDate)})`}
            handleAccordionState={(e, expanded) =>
              handleAccordionState(e, expanded, _batch)
            }
            open={batch.id === _batch.id}
          >
            <Box style={{ textAlign: "center" }}>
              {!loadingTable ? (
                <Box m={1} p={1} display="flex">
                  <SessionTable
                    meetings={_batch.meetings}
                    handleViewClick={handleViewClick}
                  />
                </Box>
              ) : (
                <LoadingIndicator />
              )}
            </Box>
          </SimpleAccordion>
        ))}
        <ContentPreview
          open={openContentViewer}
          files={selectedFile}
          title="Recordings"
          onClose={handleClose}
        />
        <FilterDialog
          open={open}
          handleClose={() => setOpen(false)}
          handleReset={handleReset}
          title={"Filter Recordings"}
        >
          <Filters
            courseTypes={courseTypes}
            courseOptions={filteredCourseOptions}
            handleChange={handleChange}
          />
        </FilterDialog>
        <BatchExpiryStatus
          open={openPaymentDialog.open}
          handleClose={()=>setOpenPaymentDialog({...openPaymentDialog, open: false})}
          batchId={openPaymentDialog.batchId}
        />
      </ContentShell>
    </AppShell>
  );
};

export default ClassRecordings;
