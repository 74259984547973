import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import { Button, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderTop: "1px solid lightgrey",
  },
  input: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1) / 2,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: "90%",
    margin: 4,
  },
  sendMessageButton: {
    // background: "#07616c",
    borderRadius: "32px",
    minHeight: "32px",
    // color: "white",
    margin: 0,
    border: "1px solid #979797",
  },
  messageTextField: {
    backgroundColor: "transparent",
    width: "calc(100% - 60px)",
  },
}));

export default function ReplyContainer({ onSave, ...props }) {
  const classes = useStyles();

  const [message, setMessage] = React.useState("");

  const [buttonLoading, setButtonLoading] = React.useState(false);

  const handleChange = (e) => setMessage(e.target.value);

  async function handleSubmit(e) {
    e.preventDefault();

    if (message.trim().length === 0) return;

    setButtonLoading(true);

    try {
      await onSave(message);
      setMessage("");
    } catch (e) {
      throw e;
    }

    setButtonLoading(false);
  }

  const handleKeyDown = async (e) =>
    e.ctrlKey && e.keyCode === 13 && message && handleSubmit(e);

  return (
    <Paper
      component="form"
      className={classes.root}
      onSubmit={handleSubmit}
      elevation={2}
      square={true}
    >
      <InputBase
        multiline
        rows={3}
        className={classes.input}
        inputProps={{
          placeholder: props.placeholder,
          autoFocus: true,
        }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={message}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <Paper
        component={"div"}
        elevation={0}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 8,
          alignItems: "center",
        }}
      >
        {/*<IconButton
          color="primary"
          className={classes.iconButton}
          aria-label="send-message"
          type="submit"
        >
          <SendIcon />
        </IconButton>
        */}
        <Button
          className={classes.sendMessageButton}
          color="primary"
          aria-label="send-message"
          type="submit"
          variant="contained"
          disabled={buttonLoading || message.length === 0}
        >
          SEND
        </Button>
        <Typography variant="caption" style={{ opacity: 0.5 }}>
          Ctrl + Enter
        </Typography>
      </Paper>
    </Paper>
  );
}
