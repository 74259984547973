import React from "react";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import useSnackbar from "hooks/useSnackbar";
import LoadingButton from "components/LoadingButton";
import { FileDownload } from "services/_clientService";
import { getAssignmentById } from "services/assignmentsService";
import { logEvent } from "services/analyticsService";
import sessionHelper from "services/sessionHelper";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogPaper: {
    height: "84%",
  },
}));

function ViewAssignment() {
  const classes = useStyles();

  const { id } = useParams();

  const history = useHistory();

  const showSnackbar = useSnackbar();

  const [loading, setLoading] = React.useState(false);

  const [downloading, setDownloading] = React.useState(false);

  const [assignment, setAssignment] = React.useState({});

  const handleClose = () => history.goBack();

  const handleDownload = async () => {
    try {
      setDownloading(true);

      await FileDownload(assignment.assignmentUrl);

      logEvent("INSTRUCTION_DOWNLOAD", {
        courseTypeId: assignment.courseTypeId,
        courseId: assignment.courseId,
        assignmentId: assignment.id,
        session: assignment.session,
        title: assignment.title,
        userId: sessionHelper.isLoggedIn() ? sessionHelper.id : "NA",
      });
    } catch (e) {
      showSnackbar(
        "Uh oh! Unable to download the assignment. Please try again"
      );
    } finally {
      setDownloading(false);
    }
  };

  React.useEffect(() => {
    getAssignmentById(id)
      .then((_assignment) => {
        setAssignment(_assignment);
        setLoading(false);
      })
      .catch((e) => {
        console.error("Error loading assignment");
        setLoading(false);
      });
  }, [id]);

  return loading ? (
    <CircularProgress />
  ) : (
    <Dialog
      open={true}
      maxWidth="md"
      fullWidth={true}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        <Typography variant="h6">Assignment > {assignment.title}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div
          style={{ marginBottom: 20, fontSize: 12 }}
          dangerouslySetInnerHTML={{ __html: assignment.description }}
        />
        <iframe
          frameBorder={0}
          title="PDF Viewer"
          height="94%"
          width="100%"
          src={assignment.assignmentUrl}
        />
      </DialogContent>
      <DialogActions>
        {sessionHelper?.permissions?.assignments?.download_assignment && (
          <LoadingButton
          loading={downloading}
          color="secondary"
          variant="contained"
          label="Download Assignment"
          onClick={handleDownload}
        />
        )}
      </DialogActions>
    </Dialog>
  );
}

ViewAssignment.propTypes = {
  open: PropTypes.bool,
};

ViewAssignment.defaultProps = {
  open: false,
};

export default ViewAssignment;
