import React from "react";
import { Formik, Form } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import useForm from "hooks/useForm";
import useSnackbar from "hooks/useSnackbar";
import ContentShell from "components/ContentShell";
import Table from "components/Table";
import FormTextInput from "components/FormTextInput";
import FormDateInput from "components/FormDateInput";
import FormSectionHeader from "components/FormSectionHeader";
import { FormPhoneInput } from "components/PhoneInput";
import { LANGUAGES, CHILD_GENDER, getProfile, updateProfile } from "services/profileService";
import sessionHelper from "services/sessionHelper";
import FormSelectInput from "components/FormSelectInput";
import FormMultipleSelectInput from "components/FormMultipleSelectInput";

const useStyles = makeStyles((theme) => ({
  formOuter: {
    padding: theme.spacing(2),
  },
  container: {
    width: "100%",
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
    },
  },
  detail: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  profilePicture: {
    width: "40%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  profile: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const formConfig = {
  name: {
    type: "string",
    isRequired: true,
  },
  email: {
    type: "string",
    isRequired: true,
  },
  phone: {
    type: "string",
    isRequired: true,
  },
  childName: {
    type: "string",
    isRequired: true,
  },
  dateOfBirth: {
    type: "date",
  },
  childGender: {
    type: "string",
  },
  languages: {
    type: "array"
  }
};

function EditProfile() {
  const classes = useStyles();

  const showSnackbar = useSnackbar();

  const [courses, setCourses] = React.useState([]);

  const { loading, formikConfig, submitForm } = useForm({
    isEdit: true,
    formConfig,
    getFormData: getProfile,
    submitForm: updateProfile,
  });

  React.useEffect(() => {
    async function init() {
      try {
        let courses = [];
        for (const mapping of sessionHelper.userCourseMappings) {
          if (mapping.batchId !== -99)
            courses.push({
              id: mapping.batchId,
              label: `${mapping.courseTypeName} ${mapping.courseName}`,
              startDate: mapping.startDate ? new Date(mapping.startDate).toLocaleDateString() : "N/A",
              endDate: mapping.endDate ? new Date(mapping.endDate).toLocaleDateString() : "N/A",
              status: mapping.status,
            })
        }

        setCourses(courses);
      } catch (e) {
        showSnackbar("Uh oh! Unable to get your profile information");
      }
    }

    init().catch((e) =>
      console.error("Error loading profile information", e.message)
    );
  }, []);

  return (
    <ContentShell title="Edit Profile" loading={loading}>
      <Paper className={classes.formOuter}>
        <Formik
          initialValues={formikConfig.initialValues}
          validationSchema={formikConfig.validationSchema}
          onSubmit={submitForm}
        >
          <Form>
            <FormSectionHeader>Child Profile</FormSectionHeader>
            <Box className={classes.container}>
              <Box className={classes.detail}>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-around"
                >
                  <Box width="45%">
                    <FormTextInput
                      id="childName"
                      name="childName"
                      label="Child Name"
                    />
                  </Box>
                  <Box width="45%">
                    <FormDateInput
                      id="dateOfBirth"
                      name="dateOfBirth"
                      label="Date of Birth"
                    />
                  </Box>
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-around"
                >
                  <Box width="45%">
                    <FormSelectInput
                      name="childGender"
                      label="Gender"
                      options={CHILD_GENDER}
                    />
                  </Box>
                  <Box width="45%">
                    <FormMultipleSelectInput
                      name="languages"
                      label="Languages"
                      options={LANGUAGES}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {sessionHelper.canAccessFeature(["MINI_PROGRAM", "PRESCHOOL"]) && (
              <>
                <FormSectionHeader>Course Information</FormSectionHeader>
                <Box style={{ marginBottom: 35 }}>
                  <Table
                    columns={[
                      { id: "label", label: "Name" },
                      { id: "startDate", label: "Start Date" },
                      { id: "endDate", label: "End Date" },
                      { id: "status", label: "Status" },
                    ]}
                    data={courses}
                    removeShadow={true}
                  />
                </Box>
              </>
            )}
            <FormSectionHeader>Parent Profile</FormSectionHeader>
            <Box className={classes.profile}>
              <FormTextInput id="name" name="name" label="Name" />
              <FormPhoneInput id="phone" name="phone" label="Phone" />
              <FormTextInput id="email" name="email" label="Email" />
            </Box>

            <Box
              width="100%"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Box>
          </Form>
        </Formik>
      </Paper>
    </ContentShell>
  );
}

export default EditProfile;
