import { Client } from "./_clientService";

async function createRoom(classId, batchId, courseId) {
  const result = await new Client({
    path: `/meetings/room`,
    payload: { classId, batchId, courseId },
  }).post();

  if (result.code !== 200) {
    throw new Error(result.message);
  }

  return result.room || {};
}

async function getTodaysMeeting(classId, batchId, courseId) {
  const result = await new Client({
    path: `/meetings/meeting?classId=${classId}&batchId=${batchId}&courseId=${courseId}`,
  }).get();

  if (result.code !== 200) {
    throw new Error(result.message);
  }

  return result.meeting;
}

async function getRecordings({ batchId, courseId }) {
  let url = `/meetings/recordings?pageSize=-99&`;

  if (batchId) url += `batchId=${batchId}&`;
  if (courseId) url += `courseId=${courseId}`;

  const result = await new Client({
    path: url,
  }).get();

  if (result.code !== 200) {
    throw new Error(result.message);
  }

  return result.data.meetings || [];
}

export { createRoom, getRecordings, getTodaysMeeting };
