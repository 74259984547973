const saveFilter = (screenName, newItem) => {
  if (localStorage.getItem("kutuki_filters")) {
    let oldItems = JSON.parse(localStorage.getItem("kutuki_filters")) || {};

    if (oldItems[screenName]) {
      let data = { ...oldItems[screenName], ...newItem };
      localStorage.setItem(
        "kutuki_filters",
        JSON.stringify({ ...oldItems, [screenName]: data })
      );
    } else {
      localStorage.setItem(
        "kutuki_filters",
        JSON.stringify({ ...oldItems, [screenName]: newItem })
      );
    }
  } else {
    localStorage.setItem(
      "kutuki_filters",
      JSON.stringify({ [screenName]: newItem })
    );
  }
};

const getFilterByScreen = (screenName) => {
  let data = null;

  if (localStorage.getItem("kutuki_filters")) {
    let oldItems = JSON.parse(localStorage.getItem("kutuki_filters")) || {};

    data = oldItems[screenName] || null;
  }

  return data;
};

const getFilter = (screenName, filterName) => {
  let value = -99;

  if (localStorage.getItem("kutuki_filters")) {
    let oldItems = JSON.parse(localStorage.getItem("kutuki_filters")) || {};
    if (oldItems[screenName]) {
      let data = { ...oldItems[screenName] };
      value = data[filterName] || -99;
    }
  }

  return value;
};

const clearFilter = () => localStorage.removeItem("kutuki_filters");

const resetFilter = (screenName) => {
  let oldItems = JSON.parse(localStorage.getItem("kutuki_filters")) || {};
  delete oldItems[screenName];
  localStorage.setItem("kutuki_filters", JSON.stringify(oldItems));
};

export { 
saveFilter, 
clearFilter, 
getFilterByScreen, 
getFilter, 
resetFilter 
};
