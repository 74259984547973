import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import GetAppIcon from "@material-ui/icons/GetApp";
import useSnackbar from "hooks/useSnackbar";
import AppShell from "components/AppShell";
import ContentShell from "components/ContentShell";
import WhatsAppButton from "components/WhatsAppButton";
import FacebookButton from "components/FacebookButton";
import LinkedInButton from "components/LinkedInButton";
import BatchExpiryStatus from "components/dialogs/BatchExpiryStatus";
import { SimpleSelectInput } from "components/FormSelectInput";
import { FileDownload } from "services/_clientService";
import { calculateExpiry } from "utils/preschoolHelper";
import { formatDate } from "utils/miscUtils";
import sessionHelper from "services/sessionHelper";
import moment from "moment";
import Logo from "assets/slide_1.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    "& > *": {
      width: "100%",
      height: "100%",
      padding: 15,
    },
  },
  batchDetails: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    margin: 6,
  },
  selectMenu: {
    minWidth: 300,
    [theme.breakpoints.down("xs")]: {
      minWidth: 150,
    },
  },
  certificate: {
    width: 540,
    height: 420,
    margin: "0 auto",
    display: "block",
    marginBottom: theme.spacing(2),
  },
  logo: {
    width: 250,
    height: 250,
    margin: "0 auto",
    display: "block",
    marginBottom: theme.spacing(2),
  },
  signature: {
    fontFamily: "Satisfy, cursive",
    marginBottom: theme.spacing(2),
  },
  shareContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const ClassesCard = (props) => {
  const {
    courseTypeName,
    courseName,
    status,
    teacherName,
    certificateUrl,
    endDate,
    course
  } = props;

  const classes = useStyles();

  const [displayPayNow, setDisplayPayNow] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  const handleClick = () => setOpen(true);

  React.useEffect(() => {
    if (courseTypeName?.toUpperCase() === "PRESCHOOL") {
      const expiryDate = moment(new Date(course?.expiryDate)).format("YYYY-MM-DD");

      const current_date = moment().format("YYYY-MM-DD");

      const days = moment(expiryDate).diff(current_date, 'days');
      
      if (days >= 0 && days <= 7)
  
      setDisplayPayNow(calculateExpiry(course?.expiryDate) || days < 8 ? true : false);
    }
    else
      setDisplayPayNow(false);
  }, [course])

  return (
    <div className={classes.root}>
      <Paper>
        <Typography variant="h5" align="center" style={{ marginBottom: 14 }}>
          {courseTypeName} {courseName}
        </Typography>
        {status === "COMPLETED" ? (
          <>
            <img
              src={certificateUrl}
              className={classes.certificate}
              alt="Kutuki Certificate"
            />
            <Typography
              variant="h4"
              align="center"
              className={classes.signature}
              gutterBottom
            >
              {teacherName}
            </Typography>
            <div className={classes.shareContainer}>
              <IconButton
                color="primary"
                onClick={() => FileDownload(certificateUrl)}
              >
                <GetAppIcon fontSize="medium" />
              </IconButton>
              <WhatsAppButton text={certificateUrl} type="icon" />
              <FacebookButton text={certificateUrl} type="icon" />
              {false && <LinkedInButton text={certificateUrl} type="icon" />}
            </div>
          </>
        ) : (
          <>
            <img src={Logo} className={classes.logo} alt="Kutuki" />
            <Typography variant="h4" align="center" gutterBottom>
              {status}
            </Typography>
            <Typography variant="h6" align="center" gutterBottom>
              {`Course ends on ${endDate.toLocaleDateString("en-GB")}`}
            </Typography>
              {displayPayNow && (
                <Box className={classes.container}>
                  <Typography variant="h6" align="center" gutterBottom style={{ fontWeight: 700 }}>
                    {sessionHelper?.paymentReminder && Object.keys(sessionHelper?.paymentReminder)?.length > 0
                      ? `Your next payment is due on ${course?.expiryDate ? formatDate(course?.expiryDate) : ""}.`
                      : `We are missing ${sessionHelper?.selectedProfile?.name}. Renew now and resume ${sessionHelper?.selectedProfile?.name}'s learning experience with Kutuki.`
                    }
                  </Typography>
                  <Button variant="contained" color="primary" onClick={handleClick}>Renew Now</Button>  
                </Box>
              )}
              <BatchExpiryStatus
                open={open}
                handleClose={handleClose}
                batchId={course?.batchId}
                isExpired={sessionHelper?.paymentOptions[course?.batchId] ? true : false}
              />
          </>
        )}
      </Paper>
    </div>
  );
};

function MyClasses() {
  const showSnackbar = useSnackbar();

  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);

  const [courses, setCourses] = React.useState([]);

  const [options, setOptions] = React.useState({});

  const [selected, setSelected] = React.useState({});

  React.useEffect(() => {
    async function init() {
      try {
        const courseOptions = sessionHelper.userCourseMappings.map((mapping) => ({
          id: mapping.batchId,
          label: `${mapping.courseTypeName} ${mapping.courseName}`,
        }));

        setOptions({ courses: courseOptions });

        setCourses(sessionHelper.userCourseMappings);

        setSelected(sessionHelper.userCourseMappings[0]);

        setLoading(false);
      } catch (e) {
        showSnackbar("Uh oh! Unable to get information about your classes");

        setLoading(false);
      }
    }

    init().catch((e) =>
      console.error("uh oh! Unable to get information about your classes")
    );
  }, []);

  const handleSelectCourse = (event) => {
    const _selected = courses.filter(
      (course) => course.batchId === event.target.value
    );
    setSelected(_selected?.[0]);
  };

  return (
    <AppShell>
      <ContentShell
        title="My Classes"
        loading={loading}
        actions={
          <SimpleSelectInput
            className={classes.selectMenu}
            fullWidth={true}
            name="courseId"
            label="Course Name"
            value={selected?.batchId}
            options={options?.courses}
            onChange={handleSelectCourse}
          />
        }
      >
        {selected && (
          <ClassesCard
            courseTypeName={selected?.courseTypeName}
            courseName={selected?.courseName}
            teacherName={selected?.teacherName}
            certificateUrl={selected?.certificateUrl}
            endDate={new Date(selected?.endDate)}
            expiryDate={selected?.expiryDate ? new Date() : ""}
            status={selected?.status}
            course={selected}
          />
        )}
      </ContentShell>
    </AppShell>
  );
}

export default MyClasses;
