import React from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import CountryCode from "utils/countryCode";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormInputShell, { InputShell } from "components/FormInputShell";
import FormHelperText from "@material-ui/core/FormHelperText";
import ReactCountryFlag from "react-country-flag";
import { Typography } from "@material-ui/core";
const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: 5,
  },
  customWidth: {
    "& div": {
      width: 320,
      height: 240,
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 8,
    border: "1px solid",
    borderColor: "#cccccc",
    borderRadius: "3px",
    paddingTop: 9.4,
    paddingBottom: 9.4,
    paddingLeft: 8,
  },
}));

const PhoneInput = ({
  defaultCountry = {
    code: "IN",
    dial_code: "+91",
    name: "India",
  },
  value = "",
  onPhoneNumberChange,
  ...props
}) => {
  const classes = useStyles();
  
  // const [dialCode, setDialCode] = React.useState("IN");
  // const [countryCode, setCountryCode] = React.useState(defaultCountryCode);
  const [nationalNumber, setNationalNumber] = React.useState("");
  const [country, setCountry] = React.useState(defaultCountry);

  const [display, setDisplay] = React.useState(false);

  const handleCountryCodeChange = (event) => {
    const country = CountryCode.find(c => c.code === event.target.value);
    setCountry(country);
    
    setDisplay(false);
  };

  const handleOpen = () => {
    setDisplay(true);
  };

  React.useEffect(() => {
    onPhoneNumberChange(`${country.dial_code}${nationalNumber}`);
  }, [country, nationalNumber]);

  React.useEffect(() => {
    if (value.length > 0) {
      if (value.includes("+")) {
        try {
          const result = phoneUtil.parseAndKeepRawInput(value);
          const code = "+" + result.getCountryCode().toString();

          setCountry(CountryCode.find(c => c.code === code) || defaultCountry);
          setNationalNumber(result.getNationalNumber());
        } catch { }
      } else {
        setNationalNumber(value);
      }
    } else {
      setNationalNumber("");
    }
  }, [value]);

  return (
    <>
      {props.readOnly ? (
        <Typography variant="body1" gutterBottom>
          {`${country.code}${nationalNumber}`}
        </Typography>
      ) : (
        <>
          <div
            className={classes.container}
            style={{ borderColor: props.touched && !!props.error ? "red" : "" }}
          >
            <FormControl className={classes.formControl} size="small">
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  className: classes.customWidth,
                }}
                disableUnderline={true}
                value={country?.code || "IN"}
                onChange={handleCountryCodeChange}
                onOpen={handleOpen}
              >
                {CountryCode.map((option, index) => (
                  <MenuItem key={index} value={option.code}>
                    <ReactCountryFlag
                      countryCode={option.code}
                      svg
                      style={{ margin: 5 }}
                    />
                    {display && option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              {...props}
              helperText={""}
              fullWidth
              InputProps={{
                disableUnderline: true,
              }}
              onChange={(e) => setNationalNumber(e.target.value)}
              value={nationalNumber}
            />
          </div>
          <FormHelperText error={props.touched && !!props.error}>
            {props.touched && !!props.error && props.error}
          </FormHelperText>
        </>
      )}
    </>
  );
};

const FormikPhoneInput = ({ defaultCountryCode = "+91", ...props }) => {
  const [field, meta, helpers] = useField(props.name);

  const { setValue } = helpers;

  const handleChange = (phone) => {
    setValue(phone);
  };

  return (
    <PhoneInput
      onPhoneNumberChange={handleChange}
      value={meta.initialValue}
      error={meta.error}
      touched={meta.touched}
      {...props}
      {...field}
    />
  );
};

const FormPhoneInput = ({ label, ...props }) => {
  return (
    <FormInputShell label={label} name={props.name}>
      <FormikPhoneInput {...props} />
    </FormInputShell>
  );
};

const SimplePhoneInput = ({ label, ...props }) => {
  return (
    <InputShell label={label} name={props.name}>
      <PhoneInput {...props} />
    </InputShell>
  );
};

export default SimplePhoneInput;
export { FormPhoneInput };
