import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FacebookIcon from "@material-ui/icons/Facebook";

const TEXT = {
  DEFAULT: "Hi,%20I%27m%20an%20issue.%20Can%20please%20help%20me?",
  LOGIN:
    "Hi,%20I%27m%20unable%20to%20login%20to%20the%20Kutuki%20WebApp.%20Can%20you%20help%20me?",
  ASSIGNMENTS:
    "Hi,%20I%27m%20unable%20to%20submit%20my%20assignment.%20Can%20you%20help%20me?",
};

const FacebookButton = ({
  type = "button",
  text = "",
  screenName = "DEFAULT",
}) => {
  const _text = text || TEXT[screenName];

  return (
    <>
      {type === "button" ? (
        <Button
          color="primary"
          target="_blank"
          href={`https://www.facebook.com/sharer/sharer.php?u=${_text}`}
          startIcon={<FacebookIcon />}
        >
          Require Help?
        </Button>
      ) : (
        <IconButton
          color="primary"
          target="_blank"
          href={`https://www.facebook.com/sharer/sharer.php?u=${_text}`}
        >
          <FacebookIcon />
        </IconButton>
      )}
    </>
  );
};

export default FacebookButton;
