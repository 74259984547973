import React from "react";
import Button from "@material-ui/core/Button";

function FreshdeskButton() {
  return (
    <Button
      href="https://kutukiassist.freshdesk.com/support/tickets/new"
      target="_blank"
      color="primary"
    >
      Require Help?
    </Button>
  );
}

export default FreshdeskButton;
