import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: 24,
  },
}));

export default function SimpleAccordion({
  title,
  open = false,
  children,
  handleAccordionState = () => {},
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion
        onChange={(e, expanded) => handleAccordionState(e, expanded)}
        expanded={open}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" component="h1">
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box style={{ width: "100%" }}> {children} </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
