import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import AppShell from "components/AppShell";
import Themes from "components/lists/Themes";
import ContentShell from "components/ContentShell";
import ThemeContent from "components/lists/ThemeContent";
import sessionHelper from "services/sessionHelper";

function LearningMaterials() {
  const location = useLocation();
  const { path } = useRouteMatch();

  return (
    <AppShell>
      {!sessionHelper?.permissions?.learning_materials?.view ? (
        <ContentShell title="Learning Materials"></ContentShell>
      ) : (
        <Switch location={location}>
          <Route exact path={path}>
            <Themes />
          </Route>

          <Route path={`${path}/:id/content`}>
            <ThemeContent />
          </Route>
        </Switch>
      )}
    </AppShell>
  );
}

export default LearningMaterials;
