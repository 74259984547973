import { Client } from "./_clientService";
import sessionHelper from "./sessionHelper";
import { getUser } from "services/usersService";

const CHILD_GENDER = [
  { id: "girl", label: "GIRL" },
  { id: "boy", label: "BOY" },
  { id: "other", label: "OTHER" }
];

const LANGUAGES = [
  { id: "hindi", label: "Hindi" },
  { id: "english", label: "English" },
  { id: "tamil", label: "Tamil" },
  { id: "telugu", label: "Telugu" },
  { id: "marathi", label: "Marathi" },
  { id: "malayalam", label: "Malayalam" },
];

async function getProfile() {
  const parent = await getUser();
  const childId = sessionHelper?.selectedProfile?.id;
  const child = childId ? parent.children.find(child => child.id === childId) : parent.children[0];

  return {
    data: {
      id: parent.id,
      name: parent.name,
      email: parent.email,
      phone: parent.phone,
      childName: child.name,
      dateOfBirth: child.dateOfBirth,
      childGender: child.gender,
      languages: child.languages,
    },
  };
}

async function updateProfile(values) {
  const parentId = sessionHelper.id;

  const childId = sessionHelper?.selectedProfile?.id;

  const phoneNumber = values.phone;


  if (!parentId || !childId) {
    throw new Error(
      "Uh oh! We are unable to save the changes to your profile."
    );
  }

  const parentUpdateResponse = await new Client({
    path: `/user/${parentId}`,
    payload: {
      name: values.name,
      phone: phoneNumber,
      emailId: values.email,
      type: "parent"
    },
  }).put();

  if (parentUpdateResponse.code !== 200 || !parentUpdateResponse.ok) {
    throw new Error(
      parentUpdateResponse?.message ||
        "Uh oh! Unable to update the profile. Please try again."
    );
  }

  const childUpdateResponse = await new Client({
    path: `/child/${childId}`,
    payload: {
      name: values.childName,
      dob: values.dateOfBirth,
      gender: values.childGender,
      languages: values.languages || [],
      parentId: parentId
    },
  }).put();

  if (childUpdateResponse.code !== 200 || !childUpdateResponse.ok) {
    throw new Error(
      childUpdateResponse?.message ||
        "Uh oh! Unable to update the profile. Please try again."
    );
  }

  return parentUpdateResponse;
}

async function getUserCourseMappingData() {

  const response = await new Client({
    path: `/user/static`,
  }).get();

  if (response.code !== 200 || !response.ok) {
    throw new Error("Uh Oh! Unable to get course information");
  }

  const batches = response.data.batches;
  const courseMappings = response.data.userCourseMappings.map(mapping => {
    const batch = batches.find(batch => batch.id === mapping.batchId);
    return {
      batchId: mapping.batchId,
      certificateUrl: mapping.certificateUrl,
      courseId: mapping.courseId,
      courseName: batch.course.name,
      courseTypeName: batch.courseType.name,
      endDate: batch.endDate,
      endTime: batch.timeSlot.endTime,
      id: mapping.id,
      startDate: batch.startDate,
      startTime: batch.timeSlot.startTime,
      status: batch.status.toUpperCase()
    }

  })


  return courseMappings || {};
}

export { CHILD_GENDER, LANGUAGES, getProfile, updateProfile, getUserCourseMappingData };
