import React from "react";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import useSnackbar from "hooks/useSnackbar";
import sessionHelper from "services/sessionHelper";
import {createSession} from "services/authService";
import { useLoadingOverlay } from "components/LoadingOverlay";
import { clearFilter } from "utils/filterUtils";

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 300,
  },
  listItem: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

function SwitchProfile({ anchorEl, onClose }) {
  const classes = useStyles();

  const showSnackbar = useSnackbar();
  
  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();

  const history = useHistory();
  
  const [profiles] = React.useState(sessionHelper.childProfiles || []);

  const handleClick = (childId, id) => async () => {
    try {
      onClose && onClose();
      showLoadingOverlay('Switching Profile...');

      localStorage.setItem("kutProfile", JSON.stringify({ id, childId }));
      clearFilter();

      await createSession(childId);
      
      sessionHelper.isOnlyWorkSheetUser ? history.push("/assignments") : history.push("/");
    } catch (e) {
      showSnackbar(e.message);
    } finally {
      hideLoadingOverlay();
    }
  };

  const open = Boolean(anchorEl);

  return (
    <Popover
      id={open ? "profile-switcher" : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      classes={{ paper: classes.paper }}
    >
      <List>
        {profiles.map(profile =>
            <ListItem
              button
              key={`child-${profile.id}`}
              className={classes.listItem}
              onClick={handleClick(profile.id, profile.parentId)}
            >
              <Avatar
                src={profile.profilePicture}
                alt={profile.name}
                className={classes.avatar}
              />
              <Typography>{profile.name}</Typography>
            </ListItem>
        )}
      </List>
    </Popover>
  )
}

export default SwitchProfile;
