import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const TEXT = {
  DEFAULT: "Hi,%20I%27m%20an%20issue.%20Can%20please%20help%20me?",
  LOGIN: "Hi,%20I%27m%20unable%20to%20login%20to%20the%20Kutuki%20WebApp.%20Can%20you%20help%20me?",
  ASSIGNMENTS: "Hi,%20I%27m%20unable%20to%20submit%20my%20assignment.%20Can%20you%20help%20me?",
  BOOKING: "Hi,%20I%27m%20unable%20to%20book%20the%20course.%20Can%20you%20help%20me?",
};

function WhatsAppButton({
  type = "button",
  text = "",
  phone = "917676186955",
  screenName = "DEFAULT"
}) {
  const _text = text || TEXT[screenName];

  return (
    <>
      {type === "button" ? (
        <Button
          color="primary"
          target="_blank" 
          href={`https://api.whatsapp.com/send?phone=${phone}&text=${_text}`}
          startIcon={<WhatsAppIcon />}
        >
          Require Help?
        </Button>
      ): (
        <IconButton
          color="primary"
          target="_blank"
          href={`https://api.whatsapp.com/send?phone=${phone}&text=${_text}`}
        >
          <WhatsAppIcon />
        </IconButton>
      )}
    </>
  );
}

export default WhatsAppButton;
