import { lightGreen, red } from "@material-ui/core/colors";

const theme = {
  palette: {
    common: {
      white: "#fff",
      black: "#000"
    },
    primary: {
      main: "#54bfcf"
    },
    secondary: {
      main: '#24424D'
    },
    success: {
      light: lightGreen[100],
      main: lightGreen[500],
      dark: lightGreen[900]
    },
    danger: {
      light: red[100],
      main: red[500],
      dark: red[900]
    },
    background: {
      default: "#f5f5f5"
    }
  },
  typography: {
    fontFamily: [ "Open Sans", "sans-serif" ].join(","),
    fontSize: 14
  },
  overrides: {
    MuiDivider: {
      root: {
        marginTop: 1,
      },
    },
    MuiButton: {
      root: {
        fontWeight: "bold",
      }
    }
  }
}

export default theme;
