import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import UnreadMessageCounter from "./messages/UnreadMessageCounter";
import SwitchProfile from "./SwitchProfile";
import { getUnreadConversations } from "services/messagesService";
import sessionHelper from "services/sessionHelper";
import { ReactComponent as NotepadIcon } from "assets/notepad.svg";
import { ReactComponent as CalendarIcon } from "assets/calendar.svg";
import defaultProfilePicture from "assets/profile.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    borderRight: "none",
    backgroundColor: theme.palette.primary.main,
    width: drawerWidth,
    overflowX: "hidden",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerButton: {
    position: "absolute",
    transition: theme.transitions.create("left", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: drawerWidth - 20,
    top: "50%",
    bottom: "50%",
    backgroundColor: "#fff",
    width: 40,
    height: 40,
    borderRadius: 40,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.grey["200"],
    },
  },
  drawerButtonShift: {
    left: -20,
  },
  listItem: {
    marginBottom: 20,
  },
  link: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.text.primary,
    textDecoration: "none",
    cursor: "pointer",
  },
  icon: {
    height: 40,
    marginRight: 5,
  },
  profileImage: {
    margin: "20px auto",
    height: 200,
    width: 200,
    borderRadius: 150,
    border: `1px solid ${theme.palette.secondary.main}`,
    objectFit: "cover",
  },
  switchProfile: {
    marginTop: 30,
    marginBottom: 10,
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  classLink: {
    marginLeft: 20
  },
  feedback: {
    position: "absolute",
    left: 35,
    bottom: 30,
    "&:hover": {
      cursor: 'pointer'
    },
  },
  help: {
    fontSize: 15
  }
}));

const Sidebar = ({ children, open, onToggleOpen }) => {
  const classes = useStyles();

  const childProfile = sessionHelper?.selectedProfile;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [unreadConversations, setUnreadConversations] = React.useState(0);

  React.useEffect(() => {
    async function fetchData() {
      const value = await getUnreadConversations();
      setUnreadConversations(value);
    }
    fetchData();
  }, [open]);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHelpClick = () =>
    window.open("https://kutukiassist.freshdesk.com/support/tickets/new");

  return (
    <>
      <div
        className={classNames(classes.drawerButton, {
          [classes.drawerButtonShift]: !open,
        })}
        onClick={onToggleOpen}
      >
        {open ? (
          <ChevronLeftIcon color="primary" />
        ) : (
          <ChevronRightIcon color="primary" />
        )}
      </div>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerHeader} />
        <div className={classes.switchProfile} onClick={handleClick}>
          <Typography variant="h5">{childProfile?.name}</Typography>
          <KeyboardArrowDownIcon />
        </div>
        <img
          className={classes.profileImage}
          src={childProfile?.profilePicture || defaultProfilePicture}
          alt={"profile pic"}
        />
        <List>
          {sessionHelper?.permissions?.teacher_feedback?.view &&
            <ListItem className={classes.listItem}>
              <Link to={"/messages"} className={classes.link}>
                <NotepadIcon className={classes.icon} />
                <Typography gutterBottom={false}>Teacher Feedback</Typography>
                <UnreadMessageCounter
                  value={unreadConversations}
                  style={{ left: 24, top: 0 }}
                />
              </Link>
            </ListItem>
          }
          {sessionHelper?.permissions?.timetable?.view &&
            <ListItem className={classes.listItem}>
              <Link to="/timetable" className={classes.link}>
                <CalendarIcon className={classes.icon} />
                <Typography gutterBottom={false}>Time Table</Typography>
              </Link>
            </ListItem>
          }
          <ListItem className={classes.classLink}>
            {sessionHelper?.permissions?.classes?.view && <>
              <Link to="/my-classes" className={classes.link}>
                <Typography style={{ fontSize: 15 }}>My Classes</Typography>
              </Link>
            </>}
          </ListItem>
        </List>
        <div className={classes.feedback} onClick={handleHelpClick}>
          <Typography className={classes.help}>Require Help?</Typography>
        </div>
      </Drawer>
      <SwitchProfile anchorEl={anchorEl} onClose={handleClose} />
    </>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool,
  onToggleOpen: PropTypes.func,
};

Sidebar.defaultProps = {
  open: true,
};

export default Sidebar;
export { drawerWidth };
