import React from "react";
import {useSnackbar as useNotistack} from "notistack";
import Button from "@material-ui/core/Button";

function useSnackbar() {
  const {enqueueSnackbar, closeSnackbar} = useNotistack();

  const handleClick = () => <Button onClick={() => closeSnackbar()}>Dismiss</Button>;

  return (message = "", variant = "error") => enqueueSnackbar(
    message,
    {
      variant,
      action: handleClick,
      autoHideDuration: 3000,
    }
  )
}

export default useSnackbar;
