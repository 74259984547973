import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import sessionHelper from "services/sessionHelper";
import { Button } from "@material-ui/core";
import { logEvent } from "services/analyticsService";
import Image from "assets/live-classes.png";

const useStyles = makeStyles((theme) => ({
  contentShell: {
    paddingTop: theme.spacing(9),
    display: "flex",
    overflow: "hidden",
    maxHeight: "100vh",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey["300"]}`,
  },
  actionsWrapper: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  container: {
    overflow: "auto",
    paddingBottom: theme.spacing(4),
  },
  content: {},
  loadingUiWrapper: {
    width: "100%",
    height: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    fontSize: 28,
    textDecoration: "none",
    fontWeight: 900,
    marginBottom: 16,
    width: "45%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      fontSize: 18
    }
  },
  containerV2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  image: {
    height: 340,
    width: 590,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      height: 220,
      width: 340
    }
  },
  button: {
    fontWeight: 700,
    fontSize: 16,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      padding: 8
    }
  }
}));

function ContentShell({ loading, title, actions, children }) {
  const classes = useStyles();

  const courses = sessionHelper.courses;

  const path = window.location.pathname

  const handleProceedClick = () => {
    logEvent("LEARN_MORE_CLICKED_ASSIGNMENTS", {
      userId: sessionHelper.isLoggedIn() ? sessionHelper.id : "NA",
      name: sessionHelper?.name || "NA",
      email: sessionHelper?.email || "NA",
      phone: sessionHelper?.phone || "NA",
    });

    return "https://app.kutuki.in/live-classes/";
  }

  return (
    <Box className={classes.contentShell}>
      {loading ? (
        <Box className={classes.loadingUiWrapper}>
          <CircularProgress />
        </Box>
      ) : (
        <Container className={classes.container}>
          <Box width="100%" className={classes.header}>
            <Box width="40%">
              {typeof title === "string" ? (
                <Typography variant="h4" component="h1">
                  {title}
                </Typography>
              ) : (
                title
              )}
            </Box>
            <Box width="50%" className={classes.actionsWrapper}>
              {actions}
            </Box>
          </Box>
          {
            (!courses.length && path === "/") ||
              (!courses.length && path === "/learning-materials") ||
              (!courses.length && path === "/assignments") ?
              <Box className={classes.containerV2}>
                <img src={Image} alt="learn_more" className={classes.image} />
                <Typography className={classes.link}>
                  To learn more about Kutuki's Teacher-assisted learning programs, click here.
                </Typography>
                <Button
                  variant="contained"
                  href={handleProceedClick()}
                  target="_blank"
                  className={classes.button}
                >
                  Learn More
                </Button>
              </Box> :
              <Box width="100%" className={classes.content}>
                {children}
              </Box>
          }
        </Container>
      )}
    </Box>
  );
}

ContentShell.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  actions: PropTypes.node,
};

ContentShell.defaultProps = {};

export default ContentShell;
